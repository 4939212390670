import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import parseISO from 'date-fns/parseISO';
import { addMiddleware, isStateTreeNode } from 'mobx-state-tree';
import React from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { EventCard, EventCardClassification } from 'src/generated/gql/graphql';
import { EmptyFocusCard, EmptyFocusCardType } from 'src/myDayToday/components/EmptyFocusCard';
import NonPatientEventCard from 'src/myDayToday/components/NonPatientEventCard';
import PopInVisitCard from 'src/myDayToday/components/PopInVisitCard';
import ScheduledEventCard from 'src/myDayToday/components/ScheduledEventCard';
import UnsignedVisitsCard from 'src/myDayToday/components/UnsignedVisitsCard';
import {
  EventTiming,
  getScheduledEventTiming,
} from 'src/myDayToday/components/getScheduledEventTiming';
import { transformPatient } from 'src/myDayToday/domain/transformPatient';
import { GET_MAIN_FOCUS_AREA_QUERY } from 'src/myDayToday/queries.gql';
import Colors from 'src/nightingale/Colors';
import useRootStore from 'src/stores/useRootStore';
import { BoulderColors } from 'src/util/brand';
import { colors } from 'src/util/colors';

/**
 * The rate at which the MDT content should be refreshed
 */
const AUTO_REFRESH_TIME_MS = 15 * 1000;

interface MainFocusAreaProps {
  currentTime: Date;
  providerId: string;
}

export const MainFocusArea = ({ currentTime, providerId }: MainFocusAreaProps) => {
  const styles = useStyles();
  const rootStore = useRootStore();

  const { data, error, isLoading, mutate } = useTypedSWR(
    [
      GET_MAIN_FOCUS_AREA_QUERY,
      {
        providerId,
      },
    ],
    {
      keepPreviousData: true,
      refreshInterval: AUTO_REFRESH_TIME_MS,
    },
  );

  if (!data && !error && isLoading) {
    // Loading state
    return <div className={styles.container}>Loading...</div>;
  }

  if (error) {
    return (
      <div className={styles.container}>
        Received error attempting to fetch next event:{' '}
        {error ? error.message : 'No next event found'}
      </div>
    );
  }

  // This listens for the eventChanged action from the MobX
  // store and triggers a re-fetch of the event data.
  // It's not clear why the `true` parameter is required (it
  // is supposed to be true by default), but the middleware
  // doesn't trigger without it.
  if (isStateTreeNode(rootStore)) {
    addMiddleware(
      rootStore,
      (action, next) => {
        if (action.name === 'eventChanged') {
          setImmediate(() => mutate());
        }

        return next(action);
      },
      true,
    );
  }

  if (
    data?.staff_myDayTodayEventCards?.nextEvent &&
    !data?.staff_myDayTodayEventCards?.nextEvent.prepInProgress
  ) {
    return renderEventCard(data.staff_myDayTodayEventCards?.nextEvent, currentTime, styles);
  }

  if (
    data?.staff_getProviderUnsignedVisits?.length &&
    data.staff_getProviderUnsignedVisits.length > 0
  ) {
    return (
      <UnsignedVisitsCard
        providerId={providerId}
        unsignedVisits={data.staff_getProviderUnsignedVisits}
      />
    );
  }

  let emptyCardType = EmptyFocusCardType.Pause;

  if (currentTime.getHours() < 9) {
    emptyCardType = EmptyFocusCardType.Morning;
  } else if (currentTime.getHours() >= 17) {
    emptyCardType = EmptyFocusCardType.EndOfDay;
  } else if (data?.staff_myDayTodayEventCards?.nextEvent?.prepInProgress) {
    emptyCardType = EmptyFocusCardType.PatientBeingPrepped;
  }
  const providerFirstName = rootStore.auth.user?.firstName;

  return (
    <div className={`${styles.container} empty`}>
      <EmptyFocusCard type={emptyCardType} providerName={providerFirstName} />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '675px',
    minHeight: '485px',
    padding: '24px 36px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: '8px',
    border: '2px solid',
    borderColor: BoulderColors.Blue4,
    background: BoulderColors.White,
    boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.05)',
    '&.empty': {
      borderWidth: 1,
      borderColor: BoulderColors.Gray2,
      boxShadow: 'none',
    },
    '&.imminent': {
      backgroundColor: BoulderColors.Blue1,
    },
    '&.late': {
      backgroundColor: Colors.NewLightRed,
      borderColor: colors.errorLight,
    },
    '&.alignTop': {
      justifyContent: 'flex-start',
    },
  },
  header: {
    color: Colors.BoulderBlue,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '145%',
    letterSpacing: '0.201px',
  },
});

function renderEventCard(nextEvent: EventCard, currentTime: Date, styles: ClassNameMap) {
  const containerClasses = [styles.container];
  const eventClassification = nextEvent.classification;
  let EventCardComponent: React.ReactNode;

  switch (eventClassification) {
    case EventCardClassification.ScheduledWithPatient:
      EventCardComponent = renderScheduledEventWithPatient(nextEvent, currentTime);
      containerClasses.push('alignTop');
      break;
    case EventCardClassification.ScheduledWithoutPatient:
      EventCardComponent = renderScheduledEventWithoutPatient(nextEvent, currentTime);
      containerClasses.push('alignTop');
      break;
    case EventCardClassification.DropIn:
      EventCardComponent = renderDropInEvent(nextEvent, currentTime);
      containerClasses.push('alignTop');
      break;
    default:
      EventCardComponent = renderUnknownEventClassification(eventClassification);
  }

  const nextEventStart = parseISO(nextEvent.start);
  const eventTiming = getScheduledEventTiming(currentTime, nextEventStart);
  containerClasses.push(eventTiming);

  return (
    <div className={containerClasses.join(' ')}>
      <div className={styles.header}>
        {eventTiming === EventTiming.Soon || eventTiming === EventTiming.Future
          ? 'Coming Soon:'
          : 'Now:'}
      </div>
      {EventCardComponent}
    </div>
  );
}

function renderScheduledEventWithPatient(data: EventCard, currentTime: Date) {
  const event = {
    id: data.id,
    start: parseISO(data.start),
    duration: data.duration,
    title: data.title,
    type: data.type,
    subType: data.subType,
  };

  return (
    <div>
      <ScheduledEventCard
        currentTime={currentTime}
        event={event}
        patient={transformPatient(data.patient)}
        visitType={data.subType}
        visitReason={data.visitReason || undefined}
      />
    </div>
  );
}

function renderScheduledEventWithoutPatient(data: EventCard, currentTime: Date) {
  const event = {
    start: parseISO(data.start),
    duration: data.duration,
    title: data.title,
    subType: data.subType,
  };

  return (
    <div>
      <NonPatientEventCard currentTime={currentTime} event={event} />
    </div>
  );
}

function renderDropInEvent(data: EventCard, currentTime: Date) {
  return (
    <div>
      <PopInVisitCard
        currentTime={currentTime}
        expectedDuration={data.duration}
        patient={transformPatient(data.patient)}
        requestId={data.requestId || ''}
        scheduledStartTime={parseISO(data.start)}
        visitType={data.subType}
        visitReason={data.visitReason || undefined}
        reasonDetails={(data.reasonDetails || undefined) as Record<string, any> | undefined}
      />
    </div>
  );
}

function renderUnknownEventClassification(eventClassification: string) {
  return <div>Unknown event type: {eventClassification}</div>;
}
