/**
 * Rounds a date to the nearest minute using the specified rounding method.
 *
 * Temporary replacement for the `roundToNearestMinutes` function from `date-fns` until we can upgrade the
 * dependency to a version that is no longer buggy.
 *
 * @see {@link https://github.com/date-fns/date-fns/issues/3429}
 * @see {@link https://stackoverflow.com/questions/10789384/round-a-date-to-the-nearest-5-minutes-in-javascript}
 */
export function roundToNearestMinutes(
  date: Date,
  options: {
    roundingMethod: 'floor' | 'ceil';
  },
): Date {
  const ms = 1000 * 60; // ms in a minute
  const roundingFn = options.roundingMethod === 'floor' ? Math.floor : Math.ceil;
  return new Date(roundingFn(date.getTime() / ms) * ms);
}
