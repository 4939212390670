import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import isNil from 'lodash/fp/isNil';
import partition from 'lodash/fp/partition';
import set from 'lodash/fp/set';
import without from 'lodash/fp/without';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useCallback, useState, MutableRefObject } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  CHART_ELEMENT_SAVE_ERROR_LOGGING,
  FLOW_ACTIVE_ELEMENT_CONDITIONAL_OVERRIDE,
} from 'src/featureFlags/currentFlags';
import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import ChartElement from 'src/nightingale/components/ChartElement/domain/ChartElement';
import {
  ChangedChartPropertyValues,
  ChartElementProps,
  ChartElementViewMode,
  ChartPropertyValueChange,
} from 'src/nightingale/components/ChartElement/types';
import { ChartUpdateContext } from 'src/nightingale/components/ChartUpdateContext/ChartUpdateContext';
import { ConditionalContext } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import { createValidChartPropertyValue } from 'src/nightingale/data/ChartProperty.utils';
import { updateChartPropertyValues } from 'src/nightingale/data/updateChartPropertyValues';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { getUpdatedSummarizationContext } from 'src/nightingale/summarization';
import type { ChartElement as TChartElement } from 'src/nightingale/types/types';
import {
  AnyChartProperty,
  ChartPropertyAction,
  ChartPropertyValueChangeSet,
  ChartPropertyValue,
  InteractionKind,
} from 'src/nightingale/types/types';
import { PebblesUpdateContext } from 'src/pebbles/PebblesUpdateContext';

export function useChartElement({
  definition,
  containerRef,
  onEdit,
  onView,
}: Pick<ChartElementProps, 'definition' | 'onEdit' | 'onView'> & {
  containerRef: MutableRefObject<HTMLElement | null>;
}) {
  const [uiMode, setUiMode] = useState<ChartElementViewMode>('view');
  const [changedPropertiesByName, setChangedPropertiesByName] =
    useState<ChangedChartPropertyValues>({});
  const [propertiesWithValidationError, setPropertiesWithValidationError] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [holdClearActiveElement, setHoldClearActiveElement] = useState(false);
  const [saveError, setSaveError] = useState<Error | null>(null);
  const [snackbarError, setSnackbarError] = useState<Error | null>(null);

  const { apolloClient } = useContext(ApolloClientContext);
  const chartContext = useContext(ChartContext);
  const { patientId, interactionKind, interactionId, interactionReferenceId } = chartContext;
  const { updateLastUpdate } = useContext(ChartUpdateContext);
  const { updateLastUpdate: pebbleUpdateLastUpdate } = useContext(PebblesUpdateContext);

  const element = new ChartElement(definition, chartContext);
  const flags = useContext(FeatureFlagContext);
  const chartElementSaveErrorLogging = flags[CHART_ELEMENT_SAVE_ERROR_LOGGING];
  const flowActiveElementConditionalOverrideEnabled =
    flags[FLOW_ACTIVE_ELEMENT_CONDITIONAL_OVERRIDE];

  useEffect(() => {
    if (element.onRefreshSnapshot) setUiMode('refresh');
  }, [chartContext.onRefreshSnapshot]);

  const hasValidationError = propertiesWithValidationError.length > 0;

  function startEditing() {
    if (element.isReadOnly) {
      return;
    }

    if (element.needsConfirmation) {
      setUiMode('confirm');
      return;
    }

    setUiMode('edit');

    if (onEdit) {
      onEdit(definition.name);
    }
  }

  function stopEditing() {
    setUiMode('view');

    if (onView) {
      onView(definition.name);
    }
  }

  function setToEditMode() {
    setUiMode('edit');
  }

  const addDefaultValuesAsChangedProperties = useCallback((): void => {
    // If there are any defaults for empty ChartProperties that were not modified then add the
    // default value to the list of properties to save. Defaults for List ChildChartProperties
    // are handled in the ListControl component.
    definition.properties.forEach(property => {
      if (!property.value && !(property.name in changedPropertiesByName) && property.default) {
        changedPropertiesByName[property.name] = {
          value: property.default.value,
        };
      }
    });
  }, [changedPropertiesByName, definition]);

  function leaveChartElement() {
    if (uiMode === 'confirm') {
      setUiMode('view');
    } else {
      addDefaultValuesAsChangedProperties();
      stopEditing();
    }
  }

  /**
   * Go from View mode to either Confirm or Edit mode, depending on what this chart element needs
   *
   * Callers should ensure they're in View mode before calling this.
   */
  function enterChartElement() {
    // clearing active element will remove the active element conditional override
    // we want to set this to true when we are in edit mode
    // and then to false when we know we are done saving
    if (flowActiveElementConditionalOverrideEnabled) setHoldClearActiveElement(true);

    if (element.needsConfirmation) {
      setUiMode('confirm');
    } else {
      startEditing();
    }
  }

  function focusChartElement() {
    containerRef.current?.focus();
  }

  /**
   * Track validation errors
   */
  function setValidationErrorForProperty(propertyName: string, hasError: boolean) {
    if (hasError && !propertiesWithValidationError.includes(propertyName)) {
      setPropertiesWithValidationError([...propertiesWithValidationError, propertyName]);
    } else if (!hasError && propertiesWithValidationError.includes(propertyName)) {
      setPropertiesWithValidationError(without(propertiesWithValidationError, propertyName));
    }
  }

  async function saveChangesets(changesets: ChartPropertyValueChangeSet[]) {
    setIsSaving(true);
    try {
      const values = changesets.map(changeset => {
        const propertyDefinition = definition.properties.find(
          ({ name }) => name === changeset.propertyName,
        );
        return createValidChartPropertyValue(changeset, propertyDefinition as AnyChartProperty);
      });

      if (apolloClient) {
        await updateChartPropertyValues(apolloClient, patientId, values, interactionReferenceId);
        updateLastUpdate?.();
      }

      setChangedPropertiesByName({});
    } catch (err) {
      setSnackbarError(err);
      if (chartElementSaveErrorLogging) {
        Sentry.captureException(err, {
          tags: {
            feature: 'saveChartElementError',
          },
        });
      }
      if (isNil(err.graphQLErrors)) {
        setSaveError(err);
      } else {
        const [inputErrors, otherErrors] = partition(
          e => e.code === 'BAD_USER_INPUT',
          err.graphQLErrors,
        );
        inputErrors.forEach(e => {
          if (e.exception?.propertyName) {
            setValidationErrorForProperty(e.exception.propertyName, true);
          } else {
            otherErrors.push(e);
          }
        });
        if (!isEmpty(otherErrors)) {
          setSaveError(err);
        }
      }
    } finally {
      if (interactionKind === InteractionKind.Pebble) {
        // If a chart element in a pebble interaction is updated, we update the pebble's updatedAt.
        // This ensures that the displayed updatedAt refreshes in real time.
        pebbleUpdateLastUpdate();
      }
      setIsSaving(false);
      if (flowActiveElementConditionalOverrideEnabled) setHoldClearActiveElement(false);
    }
  }

  const definitionWithValues: TChartElement = {
    ...definition,
    properties: definition.properties.map(property => ({
      ...property,
      interactionId: changedPropertiesByName[property.name]
        ? changedPropertiesByName[property.name]?.interactionId
        : property.interactionId,
      isEmpty: isNil(changedPropertiesByName[property.name]?.isEmpty)
        ? property.isEmpty
        : changedPropertiesByName[property.name]?.isEmpty,
      value:
        changedPropertiesByName[property.name]?.value === undefined
          ? property.value
          : changedPropertiesByName[property.name]?.value,
      notes:
        changedPropertiesByName[property.name]?.notes === undefined
          ? property.notes
          : changedPropertiesByName[property.name]?.notes,
    })),
  };

  function confirmChartElement() {
    if (!element.isConfirmed) {
      const changesets = confirmationsFromChartProperties(
        definition.properties.filter(isPropertyShown),
      );

      saveChangesets(changesets);
    }

    if (flowActiveElementConditionalOverrideEnabled) setHoldClearActiveElement(false);

    setUiMode('view');
  }

  const { conditionalContext, isPropertyShown, setConditionalContext, updateConditionalMap } =
    useContext(ConditionalContext);

  const debouncedUpdateConditionalMap = useCallback(
    debounce(
      (values: ChangedChartPropertyValues) => {
        const updatedDefinitions = definition.properties.filter(({ name }) => name in values);
        const updatedValues = getUpdatedPropertyValues(values);
        if (updateConditionalMap) {
          updateConditionalMap(updatedValues, updatedDefinitions);
        }
      },
      750,
      {
        leading: true,
        trailing: true,
      },
    ),
    [definition],
  );
  useDidUpdateEffect(() => {
    debouncedUpdateConditionalMap(changedPropertiesByName);
  }, [changedPropertiesByName]);

  useEffect(() => {
    handlePropertyChanges();
  }, [changedPropertiesByName, saveError, propertiesWithValidationError, uiMode]);

  /**
   * Responds to changes in the ChartElement's chart properties by updating the conditional context
   * and saving the appropriate changesets to the database
   *
   * Will not do anything if there are no actual changes, if we're still in edit mode, or
   * if there are outstanding errors.
   */
  function handlePropertyChanges() {
    if (uiMode === 'edit' || saveError || propertiesWithValidationError.length > 0) return;

    // Figure out which properties in this element were edited and which weren't
    const [changedProperties, unchangedProperties] = partition(
      property => property.name in changedPropertiesByName, // Only the ones that have changed
      definition.properties, // All properties in the ChartElement
    );

    if (!changedProperties.length) {
      if (flowActiveElementConditionalOverrideEnabled) setHoldClearActiveElement(false);
      return;
    }

    updateConditionalContext(changedProperties);

    let cpvsToUpdate: ChartPropertyValueChangeSet[] = getChangesets();

    if (element.needsConfirmation) {
      const confirmationChangesets = confirmationsFromChartProperties(
        unchangedProperties.filter(isPropertyShown),
      );
      cpvsToUpdate = [...cpvsToUpdate, ...confirmationChangesets];
    }

    saveChangesets(cpvsToUpdate);
  }

  function updateConditionalContext(updatedDefinitions: AnyChartProperty[]) {
    const updatedValues = getUpdatedPropertyValues(changedPropertiesByName);
    const updatedContext = getUpdatedSummarizationContext(
      conditionalContext,
      updatedValues,
      updatedDefinitions,
    );
    if (setConditionalContext) {
      setConditionalContext(updatedContext);
    }
  }

  /** Converts propertyValues to an array of changesets */
  function getChangesets(): ChartPropertyValueChangeSet[] {
    return Object.keys(changedPropertiesByName).map(key => ({
      ...changedPropertiesByName[key],
      propertyName: key,
    }));
  }

  /**
   * Create confirmation changesets for properties that aren't hidden by conditionals
   */
  function confirmationsFromChartProperties(
    properties: AnyChartProperty[],
  ): ChartPropertyValueChangeSet[] {
    return properties.map(property => ({
      value: property.value,
      isEmpty: property.isEmpty,
      notes: property.notes,
      propertyName: property.name,
      action: ChartPropertyAction.Confirm,
    }));
  }

  function setPropertyValue(name: string, cpv: ChartPropertyValueChange) {
    setChangedPropertiesByName(
      set(
        name,
        { ...changedPropertiesByName[name], ...{ ...cpv, interactionId } },
        changedPropertiesByName,
      ),
    );
  }

  function clearSnackbarError() {
    setSnackbarError(null);
  }

  return {
    definitionWithValues,
    isReadOnly: element.isReadOnly,
    isSaving,
    isFlagged: element.isFlagged,
    hasWarning: element.hasWarning,
    uiMode,
    holdClearActiveElement,

    // Errors
    saveError,
    snackbarError,
    clearSnackbarError,
    hasValidationError,
    setValidationErrorForProperty,

    // Transitions
    startEditing,
    setToEditMode,
    enterChartElement,
    leaveChartElement,
    focusChartElement,

    // Data changes
    confirmChartElement,
    setPropertyValue,
  };
}

/**
 * Transform form data into a format suitable for evaluating conditionals
 */
function getUpdatedPropertyValues(formData: ChangedChartPropertyValues): ChartPropertyValue[] {
  return Object.keys(formData).map(propertyName => {
    return {
      propertyName,
      ...('isEmpty' in formData[propertyName] && { isEmpty: formData[propertyName].isEmpty }),
      ...('notes' in formData[propertyName] && { notes: formData[propertyName].notes }),
      ...('value' in formData[propertyName] && {
        value: formData[propertyName].value,
      }),
    } as ChartPropertyValue;
  });
}
