import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getSnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect, useState } from 'react';

import RouteLink from 'src/components/general/routeLink';
import { BannerComponent } from 'src/components/pages/eventShow/eventShow.BannerComponent';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import PatientDataService from 'src/data/patients/PatientDataService';
import { PatientLookupResult } from 'src/stores/queries/userSearch';

export const ExistingPatientBanner: React.FC<{
  eventUpdated: boolean;
  eventResults: any;
}> = ({ eventUpdated, eventResults }) => {
  const classes = useStyles();

  const [existingPatient, setExistingPatient] = useState<PatientLookupResult | null>(null);

  const { apolloClient } = useContext(ApolloClientContext);

  /**
   * If inquiry event, check if phone number or email are associated with an existing patient.
   */
  const checkForExistingPatient = async (): Promise<void> => {
    const inquiryDetails = eventResults
      .map(result => getSnapshot(result))
      .find(result => result.type === 'inquiryFormDetails')?.results;
    if (!inquiryDetails || !apolloClient) {
      return;
    }

    const patientDataService = new PatientDataService(apolloClient);
    const { email, phoneNumber } = inquiryDetails;
    // Calling search twice because currently the q where input only accepts single values.
    const emailExists = email ? await patientDataService.search(email) : [];
    const phoneNumberExists = phoneNumber ? await patientDataService.search(phoneNumber) : [];

    if (emailExists.length > 0 || phoneNumberExists.length > 0) {
      setExistingPatient(emailExists[0] || phoneNumberExists[0]);
    }
  };

  useEffect(() => {
    checkForExistingPatient();
  }, [eventUpdated]);

  return (
    <BannerComponent
      className={eventUpdated && !!existingPatient ? classes.snackbarBottom : classes.snackbarTop}
      open={!!existingPatient}
    >
      <Typography className={classes.bannerText}>
        This inquiry has the same phone number or email as an{' '}
        <RouteLink
          className={classes.link}
          newWindow={false}
          route="showPatient"
          routeParams={[{ id: existingPatient?.id }]}
        >
          existing patient.
        </RouteLink>
      </Typography>
    </BannerComponent>
  );
};

const useStyles = makeStyles({
  bannerText: {
    fontSize: 'inherit',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
  snackbarTop: {
    marginTop: 60,
  },
  snackbarBottom: {
    marginTop: 160,
  },
});
