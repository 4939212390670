import { LOAD_PROBLEM_LIST } from 'src/clinicalSnapshot/queries/problemList.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { type LoadProblemListQuery } from 'src/generated/gql/graphql';

export type ClinicalProblem = Omit<
  LoadProblemListQuery['staff_getProblemSection']['problems'][number],
  '__typename'
>;

type ClinicalProblemSection = Omit<
  LoadProblemListQuery['staff_getProblemSection'],
  '__typename' | 'problems'
> & { problems: ClinicalProblem[] };

type ClinicalProblemHook = {
  data?: ClinicalProblemSection;
  error?: Error;
  loading: boolean;
};

const removeTypename = <T>(data: { __typename?: string } & T): T => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...rest } = data;
  return rest as T;
};

export const useProblemList = (patientId: string): ClinicalProblemHook => {
  const { data, error } = useTypedSWR([LOAD_PROBLEM_LIST, { patientId }]);

  const section: ClinicalProblemSection | undefined = data
    ? {
        ...removeTypename(data.staff_getProblemSection),
        problems: data.staff_getProblemSection.problems.map(removeTypename),
      }
    : undefined;

  return {
    data: section,
    error,
    loading: !data && !error,
  };
};
