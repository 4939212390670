import { makeStyles } from '@material-ui/styles';
import { getSnapshot } from 'mobx-state-tree';
import React, { MouseEventHandler } from 'react';

import AttendeeControl from 'src/components/forms/controls/attendee';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { SubType } from 'src/events/visitTypes';
import {
  EVENT_DETAILS_VALIDATOR,
  isPatientRequired,
} from 'src/shared/client/forms/validation/event';
import { EventInstance } from 'src/stores/models/event';

export const EditEventDetails: React.FC<{
  event: EventInstance;
  onSave?: (values: unknown) => Promise<unknown>;
  onCancel?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  formId?: string;
  savingDisabled?: boolean;
}> = ({ event, onSave, onCancel, formId, savingDisabled }) => {
  const styles = useEventDetailsFormStyles();
  const patientAttendees = event?.attendees.filter(attendee => attendee?.__typename === 'Patient');

  return (
    <EditForm
      item={event}
      classes={{
        buttons: styles.buttons,
        button: styles.button,
      }}
      validationSchema={EVENT_DETAILS_VALIDATOR}
      onSave={onSave}
      onCancel={onCancel}
      buttonSize="small"
      formId={formId}
      savingDisabled={savingDisabled}
    >
      <Field
        name="title"
        className={styles.formComponent}
        component={TextControl}
        label="Title"
        onKeyDown={e => e.stopPropagation()}
      />
      <div className={styles.formComponent}>
        <Field
          name="attendees"
          component={AttendeeControl}
          fixed={patientAttendees.map(attendee => getSnapshot(attendee))}
          patientRequired={isPatientRequired(event.subType as SubType)}
        />
      </div>
    </EditForm>
  );
};

const useEventDetailsFormStyles = makeStyles({
  button: {
    marginRight: 5,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  formComponent: {
    marginTop: 24,
  },
});
