import camelCase from 'lodash/camelCase';

import { ActiveVisit } from 'src/dropInClinic/hooks/useClinicRequestQueue';
import { CompletedVisit } from 'src/dropInClinic/hooks/useCompletedVisits';
import {
  QueueVisitLimit,
  useCurrentQueueLimits,
} from 'src/dropInClinic/hooks/useCurrentQueueVisitLimits';
import { STATES } from 'src/util/usStates';

type StatisticName = 'numVisitsComplete' | 'numCaseRateComplete' | 'numVisitsInProgress';

type CompletedVisitStatistic = {
  state: string;
  requestType: string;
} & {
  [statistic in StatisticName]: number;
};

type ActiveVisitStatisticData = Pick<ActiveVisit, 'requestType'> & {
  patient: Pick<ActiveVisit['patient'], 'homeState'>;
};

/** Used to display progress bars in the Completed Visits tab */
export type CompletedVisitStatisticItem = CompletedVisitStatistic & QueueVisitLimit;

/* A subset of possible pop in clinic states.
 * These are the only states that we are currently keeping track of.
 */
export const states = ['NC', 'OH', 'OR', 'WA'];

/* A subset of possible request types.
 * These are the only request types that we are currently keeping track of.
 */
export const requestTypes = ['intake', 'followUp', 'reengagement'];

/*
 * A hook that calculates the number of completed visits for each state and request type,
 * and the combined 'global' number of completed visits for each request type.
 * As a convenience, it also include the visit limit with each CompletedVisitStatistic.
 */
export const useCompletedVisitStatistics = (
  completedVisits: CompletedVisit[] | null,
  activeVisits: ActiveVisitStatisticData[] | null,
): CompletedVisitStatisticItem[] => {
  const queueLimits = useCurrentQueueLimits();

  if (!completedVisits) {
    return [];
  }

  // We want both the individual state AND combined global statistics for each request type.
  const stateStatistics = calculateStateStatistics(completedVisits, activeVisits ?? []).map(s =>
    addNumVisitsLimits(s, queueLimits),
  );
  // so we calculate the global using the state statistics as the source of truth...
  const globalStatistics = calculateGlobalStatistics(stateStatistics).map(s =>
    addNumVisitsLimits(s, queueLimits),
  );

  return [...globalStatistics, ...stateStatistics];
};

/*
 * Initializes the collection of state statistics with 0 completed visits for each state and request type.
 * The collection is initialized with all possible combinations of states and request types, and ordered by state then request type.
 */
export const initializeStateStatistics = (): CompletedVisitStatistic[] =>
  states.flatMap(state =>
    requestTypes.map(requestType => ({
      state,
      requestType,
      numVisitsComplete: 0,
      numCaseRateComplete: 0,
      numVisitsInProgress: 0,
    })),
  );

/*
 * Builds a collection of state statistics by incrementing the number of completed visits for each state and request type.
 */
export const calculateStateStatistics = (
  completedVisits: CompletedVisit[],
  activeVisits: ActiveVisitStatisticData[],
) => {
  const stateStatistics = initializeStateStatistics();

  completedVisits.forEach(visit => {
    const state = STATES[visit.patient.homeState]?.abbreviation;
    const requestType = camelCase(visit.requestType);

    const targetStateAndRequestType = stateStatistics.find(
      s => s.state === state && s.requestType === requestType,
    );

    if (!targetStateAndRequestType) {
      return;
    }

    targetStateAndRequestType.numVisitsComplete += 1;

    if (visit.patient.payorRateModel === 'caseRate') {
      targetStateAndRequestType.numCaseRateComplete += 1;
    }
  });

  activeVisits.forEach(visit => {
    const state = STATES[visit.patient.homeState]?.abbreviation;
    const requestType = camelCase(visit.requestType);

    const targetStateAndRequestType = stateStatistics.find(
      s => s.state === state && s.requestType === requestType,
    );

    if (!targetStateAndRequestType) {
      return;
    }

    targetStateAndRequestType.numVisitsInProgress += 1;
  });

  return stateStatistics;
};

/*
 * Builds a collection of "global" statistics for each requestType.
 */
export const calculateGlobalStatistics = (stateStatistics: CompletedVisitStatistic[]) =>
  requestTypes.map(requestType => ({
    state: 'global',
    requestType,
    numVisitsComplete: sumStatisticPerRequestType(
      stateStatistics,
      requestType,
      'numVisitsComplete',
    ),
    numVisitsInProgress: sumStatisticPerRequestType(
      stateStatistics,
      requestType,
      'numVisitsInProgress',
    ),
    numCaseRateComplete: sumStatisticPerRequestType(
      stateStatistics,
      requestType,
      'numCaseRateComplete',
    ),
  }));

/*
 * Sums an arbitrary statistic for a given request type across all states.
 */
const sumStatisticPerRequestType = (
  completedVisitStatistics: CompletedVisitStatistic[],
  requestType: string,
  statisticName: StatisticName,
) =>
  completedVisitStatistics
    .filter(s => s.requestType === requestType)
    .reduce((sum, s) => sum + s[statisticName], 0);

/*
 * Helper function that allows us to tack on the visit limit from the queue settings to each of the CompletedVisitStatistics.
 */
export const addNumVisitsLimits = (
  statistic: CompletedVisitStatistic,
  queueLimits: QueueVisitLimit[],
) => ({
  ...statistic,
  numVisitsLimit: queueLimits.find(
    l => l.state === statistic.state && l.requestType === statistic.requestType,
  )?.numVisitsLimit,
});
