import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import Colors from 'src/nightingale/Colors';
import { DISABLED_TOOLTIP } from 'src/shared/util/events';
import { colors } from 'src/util/colors';

const Header = ({
  isEditing = false,
  title,
  preVisitDisplayFlagEnabled = false,
  onCancel = () => null,
  onSave = () => null,
  formId,
  savingDisabled = false,
  hideEditIcon = false,
  disableEdit = false,
  disableTooltip = DISABLED_TOOLTIP,
  onEdit = () => null,
}: {
  isEditing?: boolean;
  title?: string | null;
  preVisitDisplayFlagEnabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  formId?: string;
  savingDisabled?: boolean;
  hideEditIcon?: boolean;
  disableEdit?: boolean;
  disableTooltip?: string;
  onEdit?: () => void;
}) => {
  const styles = useStyles({ preVisitDisplayFlagEnabled });

  return (
    <div className={styles.header}>
      <Typography className={styles.heading} variant="h6">
        {title}
      </Typography>
      {isEditing && (
        <div className={styles.topButtonsEdit}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className={styles.button}
            onClick={onCancel}
            tabIndex="-1"
          >
            Cancel
            <CancelIcon className={styles.rightIcon} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.button}
            onClick={onSave}
            form={formId}
            type="submit"
            disabled={savingDisabled}
          >
            Save
            <SaveIcon className={styles.rightIcon} />
          </Button>
        </div>
      )}
      {!isEditing && !hideEditIcon && (
        <Tooltip title={disableEdit ? disableTooltip : ''}>
          <span>
            <IconButton
              disabled={disableEdit}
              className={styles.topButtonsView}
              onClick={onEdit}
              data-testid="edit-icon"
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { preVisitDisplayFlagEnabled?: boolean }>(theme => ({
  topButtonsView: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
    position: ({ preVisitDisplayFlagEnabled }) =>
      preVisitDisplayFlagEnabled ? 'absolute' : undefined,
    right: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 0 : undefined),
    top: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 20 : undefined),
    '&:hover': {
      cursor: 'pointer',
    },
    padding: '0px 5px 5px 5px',
  },
  topButtonsEdit: {
    position: ({ preVisitDisplayFlagEnabled }) =>
      preVisitDisplayFlagEnabled ? 'absolute' : undefined,
    right: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 0 : undefined),
    top: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 5 : undefined),
    paddingBottom: 5,
  },
  heading: {
    color: colors.taupe,
  },
  header: {
    alignItems: 'flex-end',
    fontSize: 20,
    height: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 0 : 40),
    position: ({ preVisitDisplayFlagEnabled }) =>
      preVisitDisplayFlagEnabled ? 'relative' : undefined,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: Colors.Gray5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    paddingBottom: 5,
    '&:not(:first-child)': {
      marginLeft: 5,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  '@media print': {
    topButtonsView: {
      display: 'none',
    },
    topButtonsEdit: {
      display: 'none',
    },
  },
  iconContainer: {
    position: ({ preVisitDisplayFlagEnabled }) =>
      preVisitDisplayFlagEnabled ? 'absolute' : undefined,
    right: ({ preVisitDisplayFlagEnabled }) => (preVisitDisplayFlagEnabled ? 0 : undefined),
  },
}));

export default Header;
