import { Theme, makeStyles } from '@material-ui/core';

import { Colors } from 'src/common/ui/base';

export const useButtonStyles = makeStyles<Theme>(theme => ({
  button: {
    borderColor: `${Colors.Gray6}55`,
    borderRadius: theme.spacing(0.5),
    color: Colors.Gray6,
    fontSize: theme.typography.fontSize,
    fontFamily: '"Nunito", "Nunito Sans"',
    textTransform: 'none',
    padding: '1px 5px',
    minWidth: 0,
  },
  iconButton: {
    border: 'solid 1px',
    padding: '1px 1.5px',
  },
  neutral: {
    borderColor: Colors.Blue3,
    color: Colors.CoolGray8,
  },
  success: {
    borderColor: Colors.Blue3,
    color: Colors.CoolGray8,
  },
  warning: {
    borderColor: Colors.Yellow2,
    color: Colors.Yellow2,
    backgroundColor: Colors.Yellow1,
    fontWeight: 600,
  },
  error: {
    borderColor: Colors.Red2,
    color: Colors.Red2,
    backgroundColor: Colors.Red1,
    fontWeight: 600,
  },
}));
