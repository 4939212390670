import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import compact from 'lodash/fp/compact';
import React, { ReactElement, ReactNode, useContext } from 'react';

import ConversationListItem from 'src/chat/ConversationListItem';
import { SET_CONVERSATION_TEAM_ASSIGNMENT_MUTATION } from 'src/chat/queries';
import { useChatTeamAssignments } from 'src/chat/useChatTeamAssignments';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Tooltip from 'src/components/general/Tooltip';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { MaterialStyle } from 'src/shared/types/styles';
import { ConversationInstance } from 'src/stores/chat';
import { colors } from 'src/util/colors';

export type ConversationListProps = {
  currentConversation: ConversationInstance;
  handleSelectConversation: (conversation: ConversationInstance) => void;
  headerIcon: ReactElement;
  headerIconAction: () => void;
  headerIconTooltip: string;
  heading: string;
  warn: boolean;
  isOtherConversations: boolean;
  testID: string;
  conversations?: ConversationInstance[];
  children?: ReactNode[];
  defaultExpanded?: boolean;
  onChange?: (event: any, expanded: boolean) => void;
};

const ConversationList = ({
  children,
  classes,
  conversations,
  currentConversation,
  defaultExpanded = false,
  handleSelectConversation,
  headerIcon,
  headerIconAction,
  headerIconTooltip,
  heading,
  warn,
  isOtherConversations,
  testID,
  onChange,
}: ConversationListProps & MaterialStyle<ReturnType<typeof styles>>) => {
  const featureFlagContext = useContext(FeatureFlagContext);
  const enableChatTeamAssignments = featureFlagContext.chatTeamAssignments ?? false;

  const { apolloClient } = useContext(ApolloClientContext);

  const conversationIdList = compact(conversations?.map(c => c?.id));

  const [assignmentMap, revalidate] = useChatTeamAssignments(
    conversationIdList,
    apolloClient,
    enableChatTeamAssignments,
  );

  const updateTeamAssignment = (conversationId, teamId) => {
    if (!apolloClient) {
      return;
    }

    apolloClient
      .mutate<{ teamId: string }>({
        mutation: SET_CONVERSATION_TEAM_ASSIGNMENT_MUTATION,
        variables: { conversationId, teamId },
      })
      .then(() => revalidate());
  };

  return (
    <>
      <Accordion
        onChange={onChange}
        className={classes.container}
        defaultExpanded={defaultExpanded}
        data-testid={testID}
      >
        <AccordionSummary
          classes={{
            root: classes.heading,
            expanded: classes.expandedHeader,
            content: classes.summaryContent,
          }}
          className={classNames({ warn })}
        >
          <ExpandMoreIcon className={classes.expandIcon} />
          <Typography variant="body2" className={classes.headingText}>
            {heading}
          </Typography>
          {headerIcon && (
            <div className={classes.createContainer}>
              <Tooltip title={headerIconTooltip} data-testid={headerIconTooltip}>
                <IconButton
                  onClick={evt => {
                    // Prevent this click from toggling the expansion
                    evt.stopPropagation();
                    headerIconAction();
                  }}
                >
                  {headerIcon}
                </IconButton>
              </Tooltip>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
          {(conversations ?? []).map((conversation, index) => (
            <ConversationListItem
              teamId={assignmentMap[conversation.id]?.teamId}
              key={conversation.id || index}
              conversation={conversation}
              onClick={() => handleSelectConversation(conversation)}
              onChangeTeamAssignment={updateTeamAssignment}
              enableChatTeamAssignments={enableChatTeamAssignments}
              isOtherConversations={isOtherConversations}
              currentConversation={currentConversation}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const styles = theme => {
  const transition = {
    duration: theme.transitions.duration.shortest,
  };
  return {
    container: {
      // Override material UI default styles
      boxShadow: 'none',
      margin: '0 !important',
    },
    createContainer: {
      paddingRight: '0 !important', // Overriding Material UI styles
      position: 'absolute',
      right: 0,
      top: 0,
    },
    heading: {
      backgroundColor: '#F0EFEA',
      color: colors.taupe,
      marginBottom: 5,
      minHeight: '48px !important',
      paddingLeft: 32,
      paddingRight: 0,
      '&.warn': {
        backgroundColor: lighten(theme.palette.warning.main, 0.8),
      },
    },
    headingText: {
      color: colors.taupe,
    },
    summaryContent: {
      // Overriding material UI default styles
      margin: '12px 0 !important',
    },
    expandedHeader: {
      marginBottom: 0,

      // Rotate the expandMoreIcon
      '& > svg': {
        transform: 'translateY(-50%) rotate(360deg)',
      },
    },
    // Primarily copy-pasted from Material UI's AccordionSummary
    // We're not using theirs because we want the icon to be on the left, which
    // requires the component to be placed in a slightly different place.
    expandIcon: {
      paddingLeft: 0,
      position: 'absolute',
      top: '50%',
      left: 8,
      transform: 'translateY(-50%) rotate(270deg)',
      transition: theme.transitions.create('transform', transition),
      '&:hover': {
        // Disable the hover effect for the IconButton,
        // because a hover effect should apply to the entire Expand button and
        // not only to the IconButton.
        backgroundColor: 'transparent',
      },
    },
    details: {
      flexDirection: 'column',
      padding: 0,
    },
  } as const;
};

export default withStyles(styles)(ConversationList);
