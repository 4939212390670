import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import { link } from 'src/clinicalSnapshot/commonStyles';

interface ConflictingDspDataAlertArgs {
  patientId: string;
}
const ConflictingDspDataAlert = ({ patientId }: ConflictingDspDataAlertArgs) => {
  const styles = useStyles();

  return (
    <div className={classNames([styles.alert, styles.fields])}>
      <div className={styles.alertContainer}>
        <InfoIcon className={styles.alertIcon} fontSize="small" />
        <span>
          Conflicting data in the{' '}
          <a className={styles.link} href={`/patients/${patientId}/overview#oftPeriods`}>
            Drug Screen Periods
          </a>
          . Please reach out to support for assistance.
        </span>
      </div>
    </div>
  );
};

export default ConflictingDspDataAlert;

const useStyles = makeStyles({
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  alertIcon: {
    marginRight: 16,
  },
  alert: {
    display: 'flex',
    padding: '8px 16px',
    color: '#9e0012',
    border: '2px solid #9e0012',
    background: '#f9e6e8',
    borderRadius: 0,
  },
  link: {
    ...link,
  },
  fields: {
    position: 'static',
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 600,
    lineHeight: '17.4px',
  },
});
