import { makeStyles } from '@material-ui/core';
import React from 'react';

import { link, listItem } from 'src/clinicalSnapshot/commonStyles';
import Collapsible from 'src/clinicalSnapshot/components/Collapsible';
import ProblemItem from 'src/clinicalSnapshot/components/ProblemItem';
import { useProblemList, ClinicalProblem } from 'src/clinicalSnapshot/hooks/useProblemList';
import { Colors } from 'src/common/ui/base';
import { SectionHeader } from 'src/common/ui/components';

interface ProblemsDataArgs {
  patientId: string;
}

const ProblemsData = ({ patientId }: ProblemsDataArgs) => {
  const styles = useStyles();
  const { data: { problems = [], externalProblems = [] } = {} } = useProblemList(patientId);

  return (
    <div>
      <SectionHeader>Problems</SectionHeader>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}>Problem List</div>
        <ul className={styles.list}>
          {problems.length === 0 && <li className={styles.listItem}>None</li>}
          {problems.map((problem: ClinicalProblem) => (
            <ProblemItem patientId={patientId} problem={problem} />
          ))}
        </ul>
      </div>

      {externalProblems.length > 0 && (
        <Collapsible
          title={`External Problems (${externalProblems.length})`}
          id="external-problems"
          content={
            <ul className={styles.list}>
              {externalProblems.map((externalProblem: string) => {
                return (
                  <li className={styles.listItem} key={externalProblem}>
                    {externalProblem}
                  </li>
                );
              })}
            </ul>
          }
        />
      )}
    </div>
  );
};

export default ProblemsData;

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: Colors.Gray5,
  },
  sectionContainer: {
    paddingLeft: '16px',
  },
  link,
  list: {
    margin: 0,
    paddingBottom: '8px',
    marginBottom: '8px',
    paddingLeft: '24px',
  },
  listItem,
});
