import { ApolloError } from 'apollo-client';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { requestTypeMap } from 'src/dropInClinic/RequestType';
import { SCHEDULE_LOGS_QUERY } from 'src/dropInClinic/hooks/useScheduleLogs.gql';
import { UnscheduledRequestReason } from 'src/dropInClinic/types';
import {
  Staff_ClinicScheduleLog as StaffClinicScheduleLog,
  Staff_ScheduleLogType as GraphqlScheduleLogType,
} from 'src/generated/gql/graphql';

export type AlternateProvider = {
  providerId: string;
  providerName: string;
};

export type ScheduleBlock = {
  startTime: string;
  duration: number;
  isFree: boolean;
  requestId: string | null;
  alternateProviders?: AlternateProvider[];
};

export type ScheduleMap = {
  [providerId: string]: {
    providerId: string;
    providerName: string;
    remainingIntakes: number;
    blocks: ScheduleBlock[];
  };
};

export type ScheduleLogQueue = {
  requestId: string;
  requestType: string;
  patient: { id: string; firstName: string; lastName: string };
  queuedAt: string;
  bonuses: Record<string, number>;
};

export type UnscheduledRequest = {
  requestId: string;
  reason: UnscheduledRequestReason;
};

export type ScheduleLog = StaffClinicScheduleLog & {
  log: {
    scheduleMap: ScheduleMap;
    unscheduledRequests: UnscheduledRequest[];
    prioritizedQueue: ScheduleLogQueue[];
    queueBeforePrioritization: ScheduleLogQueue[];
  };
};

export type RequestChangeLog = StaffClinicScheduleLog & {
  log: {
    request: {
      id: string;
      patientId: string;
      providerId: string;
      requestType: keyof typeof requestTypeMap;
      status: string;
    };
  };
};

type AvailabilityLog = {
  id: string;
  providerId: string;
  type?: string;
  start: string;
  duration: string;
  status: string;
};

export type AvailabilityCreateLog = StaffClinicScheduleLog & {
  log: AvailabilityLog;
};

export type AvailabilityUpdateLog = StaffClinicScheduleLog & {
  log: {
    before: AvailabilityLog;
    after: AvailabilityLog;
  };
};

export type Log = AvailabilityCreateLog | AvailabilityUpdateLog | RequestChangeLog | ScheduleLog;

export { GraphqlScheduleLogType as ScheduleLogType };

export const useScheduleLogs = (
  searchDate: Date | null,
): {
  data: Log[] | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, isLoading, error } = useTypedSWR(
    [SCHEDULE_LOGS_QUERY, { searchDate: searchDate ? searchDate.toISOString() : null }],
    { keepPreviousData: true, revalidateOnFocus: false },
  );

  return {
    data: data?.staff_getScheduleLogs as Log[],
    isLoading,
    error,
  };
};
