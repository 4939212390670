import React from 'react';

import { Colors } from 'src/common/ui/base';

type BoulderProgressBarSection = {
  value: number;
  color: string;
};

type BoulderProgressBarProps = {
  maximum: number;
  values: BoulderProgressBarSection[];
};

export const BoulderProgressBar = (props: BoulderProgressBarProps) => {
  const sectionElements = props.values.map(({ value, color }) => {
    return (
      <div
        style={{
          transition: 'width 0.5s',
          backgroundColor: color,
          width: `${(value / props.maximum) * 100}%`,
          height: '100%',
        }}
      />
    );
  });

  return (
    <div
      style={{
        display: 'flex',
        overflow: 'hidden',
        boxSizing: 'border-box',
        height: '20px',
        borderRadius: '4px',
        backgroundColor: Colors.CoolGray2,
      }}
    >
      {sectionElements}
    </div>
  );
};
