import { gql } from 'src/generated/gql/gql';

export const LOAD_PROBLEM_LIST = gql(`
  query LoadProblemList($patientId: ID!) {
    staff_getProblemSection(patientId: $patientId) {
      problems {
        title
        flaggedNote
        flagged
        propertyName
      }
      externalProblems
    }
  }
`);
