import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { query } from 'src/shared/util/graphqlBuilder';
import logger from 'src/shared/util/logger';
import { EventInstance } from 'src/stores/models/event';

type EventFetcherProps = {
  render: any;
  eventId: string;
  // eventProps contains all of the fields that we want the query to return. If we want to
  // also return subfields it would look something like:
  // `recurrence {
  //    type
  //    freq
  // }`
  // To describe the case of fields with subfields we add string to the type.
  eventProps: Array<keyof EventInstance | string>;
};

const EventFetcher = ({ render, eventId, eventProps }: EventFetcherProps) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const fetcher = useMemo(
    () =>
      async function eventFetcher() {
        if (!apolloClient) {
          return null;
        }

        const { data } = await apolloClient.query<{ eventInstance: EventInstance }>({
          query: query({
            variables: { eventId: 'ID' },
            selectors: [
              {
                name: 'eventInstance',
                args: { id: '$eventId' },
                selectors: eventProps,
              },
            ],
          }),
          variables: { eventId },
        });
        return data;
      },
    [apolloClient, eventId, eventProps],
  );

  const { data, error } = useSWR(`event-${eventId}`, fetcher);
  if (error) logger.warn(error);

  return data ? render({ event: data.eventInstance }) : null;
};

export default EventFetcher;
