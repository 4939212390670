/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import React, { useEffect, useRef } from 'react';

import { Spacing } from 'src/common/ui/base/Spacing';
import { TypographyStyles } from 'src/common/ui/base/Typography';

type ButtonProps = React.PropsWithChildren<
  {
    color?: MuiButtonProps['color'];
    'data-testid'?: string;
    disabled?: boolean;
    focus?: boolean;
    variant?: 'contained' | 'text';
  } & Pick<MuiButtonProps, 'color' | 'endIcon' | 'startIcon' | 'type' | 'onClick'>
>;

export const Button = ({
  'data-testid': dataTestid,
  children,
  color = 'primary',
  disabled,
  focus,
  onClick,
  type,
  variant = 'contained',
  startIcon,
}: ButtonProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (focus) btnRef?.current?.focus();
  }, []);
  return (
    <MuiButton
      css={css`
        ${TypographyStyles.buttonText1}
        border-radius: 0;
        padding: ${Spacing.Unit2}px ${Spacing.Unit3}px;
        line-height: 17.04px;
      `}
      disableElevation
      data-testid={dataTestid || 'nds-component-button'}
      disabled={disabled}
      onClick={onClick}
      ref={btnRef}
      variant={variant}
      type={type}
      color={color}
      startIcon={startIcon}
    >
      {children}
    </MuiButton>
  );
};
