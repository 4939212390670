import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { ArrowRight, ArrowDropUp } from '@material-ui/icons';
import React from 'react';

import { Colors } from 'src/common/ui/base';

interface CollapsibleArgs {
  title: string;
  id: string;
  content: React.ReactNode;
}

const Collapsible = ({ title, id, content }: CollapsibleArgs) => {
  const styles = useCollapsibleStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion className={styles.accordion} expanded={expanded === id} onChange={handleChange(id)}>
      <AccordionSummary
        IconButtonProps={{ disableRipple: true }}
        disableRipple
        expandIcon={
          expanded ? (
            <ArrowDropUp className={styles.accordionToggle} />
          ) : (
            <ArrowRight className={styles.accordionToggle} />
          )
        }
        className={styles.accordionSummary}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div className={styles.sectionTitle}>{title}</div>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>{content}</AccordionDetails>
    </Accordion>
  );
};

export default Collapsible;

const useCollapsibleStyles = makeStyles({
  accordionToggle: {
    padding: 0,
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: 0,
    marginBottom: '32px',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    marginTop: '0',
    height: '16px',
    padding: 0,
    paddingLeft: '6px',
    '&.MuiAccordionSummary-root': {
      minHeight: '16px',
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '16px',
    },
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: '16px',
    },
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: Colors.Gray5,
  },
});
