import { Colors } from 'src/common/ui/base/Colors';

export const link = {
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
  color: Colors.BlueSpruce,
  textDecoration: 'underline',
};

export const listItem = {
  padding: '4px 16px',
  borderRadius: '12px',
  fontWeight: 600,
  fontSize: '14px',
};
