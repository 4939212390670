import { createTheme } from '@mui/material';

import { Colors } from 'src/common/ui/base/Colors';
import { Spacing } from 'src/common/ui/base/Spacing';
import {
  chipCss,
  formHelperCss,
  inputCss,
  labelCss,
  tooltipCss,
} from 'src/common/ui/base/Theme/Theme.overrides.css';
import { NunitoSansFontStack, TypographyStyles } from 'src/common/ui/base/Typography';

export const Theme = createTheme({
  // This option makes theme colors available as CSS vars: `--mui-palette-primary-main`
  // See https://mui.com/material-ui/customization/theming/#css-theme-variables
  cssVariables: true,
  palette: {
    primary: {
      main: Colors.Blue4,
      dark: Colors.Blue6,
      light: Colors.Blue3,
      contrastText: Colors.White,
    },
    secondary: {
      main: Colors.Blue3,
      dark: Colors.Blue4,
      light: Colors.Blue2,
      contrastText: Colors.White,
    },
    success: {
      main: Colors.Green2,
      dark: Colors.Green2,
      light: Colors.Green1,
      contrastText: Colors.White,
    },
    error: {
      main: Colors.Red2,
      dark: Colors.Red2,
      light: Colors.Red1,
      contrastText: Colors.White,
    },
    info: {
      main: Colors.InfoBlue2,
      dark: Colors.InfoBlue2,
      light: Colors.InfoBlue1,
      contrastText: Colors.White,
    },
    warning: {
      main: Colors.Yellow2,
      dark: Colors.Yellow2,
      light: Colors.Yellow1,
      contrastText: Colors.White,
    },
  },
  typography: {
    fontFamily: NunitoSansFontStack,
    fontSize: 16,
    h1: TypographyStyles.header1,
    h2: TypographyStyles.header1,
    h3: TypographyStyles.subheader1,
    h4: TypographyStyles.subheader2,
    h5: TypographyStyles.subheader3,
    h6: TypographyStyles.subheader3,
    body1: TypographyStyles.body1,
    body2: TypographyStyles.body2,
    button: undefined,
    caption: undefined,
    overline: undefined,
  },
  spacing: [
    Spacing.Unit1,
    Spacing.Unit2,
    Spacing.Unit3,
    Spacing.Unit4,
    Spacing.Unit5,
    Spacing.Unit6,
  ],
  components: {
    MuiChip: { styleOverrides: { root: chipCss } },
    MuiFormHelperText: { styleOverrides: { root: formHelperCss } },
    MuiFormLabel: { styleOverrides: { root: labelCss } },
    MuiInput: { styleOverrides: { root: inputCss } },
    MuiInputLabel: { styleOverrides: { root: labelCss } },
    MuiPaper: { defaultProps: { square: true } },
    MuiTooltip: { styleOverrides: { tooltip: tooltipCss } },
  },
});
