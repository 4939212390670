import { gql } from 'src/generated/gql';

export const LOAD_CLINICAL_SNAPSHOT_VISIT_DATA = gql(`
 
 query LoadClinicalSnapshotVisitData($patientId: ID!) {
    staff_getPatientVisits(patientId: $patientId) {
      bridgeVisit {
        id
        visitDate
        visitSubType
        providers {
          id
          firstName
          lastName
          ... on Provider {
          	teamRole
          }
        }
      }
      lastClinicalVisit {
        id
        visitDate
        visitSubType
        providers {
          id
          firstName
          lastName
          ... on Provider {
          	teamRole
          }
        }
      }
      visitCadence {
        visitCadence
        justification
        otherDetails
      }
    }
  }
`);
