import { IconButton, makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import keyBy from 'lodash/fp/keyBy';
import isEqual from 'lodash/isEqual';
import React, { useRef, useState } from 'react';

import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import Colors from 'src/nightingale/Colors';
import { ReactComponent as DeleteIcon } from 'src/nightingale/assets/list_delete.svg';
import {
  ControlFactory,
  createControlProps,
} from 'src/nightingale/components/ChartProperty/controls/ControlFactory';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { getConditionalMap } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.conditions';
import type { ListControlProps } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.types';
import { isEmptyItem } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.utils';
import { getEmptyValueForChartProperty } from 'src/nightingale/data/ChartProperty.utils';
import { useUnmountEffect } from 'src/nightingale/hooks/useUnmountEffect';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import type { ListChartProperty, SimpleChartProperty } from 'src/nightingale/types/types';

/**
 * Styles
 */
const useStyles = makeStyles({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: 16,
  },
  listItem: {
    padding: 0,
    marginTop: 16,
    '&:first-child': {
      marginTop: 0,
    },
  },
  footer: {
    float: 'right',
  },
  iconButton: {
    marginTop: 5,
    marginBottom: -12,
    '& svg': {
      color: Colors.Stillwater,
      height: 28,
      width: 28,
      '& path': {
        fill: Colors.Stillwater,
      },
    },
    '&:hover svg': {
      color: Colors.BlueSpruce,
      '& path': {
        fill: Colors.BlueSpruce,
      },
    },
  },
});

/**
 * List Control edit mode component
 */
type ListControlEditProps = {
  editingItemId: string | null;
  setEditingItemId: React.Dispatch<React.SetStateAction<string | null>>;
  setInternalValue: React.Dispatch<
    React.SetStateAction<
      Array<{
        [key: string]: any;
      }>
    >
  >;
} & Pick<
  ListControlProps,
  | 'autoFocus'
  | 'conditions'
  | 'disabled'
  | 'hasEmptyValue'
  | 'interactionId'
  | 'isRequired'
  | 'label'
  | 'name'
  | 'onError'
  | 'properties'
  | 'value'
>;

export const ListControlEdit: React.FC<ListControlEditProps> = ({
  conditions,
  disabled,
  editingItemId,
  hasEmptyValue,
  interactionId,
  isRequired,
  label,
  name,
  onError,
  properties,
  setEditingItemId,
  setInternalValue,
  value = [],
}) => {
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const keyedValue = keyBy('_internalListItemId', value);
  const item = editingItemId ? keyedValue[editingItemId] : null;

  const [conditionalMap, setConditionalMap] = useState(
    item ? getConditionalMap({ properties, conditions }, item) : {},
  );

  useUnmountEffect(() => {
    if (item && isEmptyItem(item)) doDelete();
  }, [item]);

  const onConfirm = () => {
    setEditingItemId(null);
  };

  const onDelete = () => {
    if (item && isEmptyItem(item)) doDelete();
    else setDeleteDialog(true);
  };

  const doDelete = () => {
    if (editingItemId) delete keyedValue[editingItemId];
    setInternalValue(Object.values(keyedValue));
    setEditingItemId(null);
  };

  const updateConditionalMap = (newItem: NonNullable<ListChartProperty['value']>[number]) => {
    const updatedConditionalMap = getConditionalMap({ properties, conditions }, newItem);
    if (!isEqual(conditionalMap, updatedConditionalMap)) {
      setConditionalMap(updatedConditionalMap);
    }
  };

  const requiredChartProperties = useRequiredChartProperties();

  if (!editingItemId || !item) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <LabeledFormControl
        hasEmptyValue={hasEmptyValue}
        isRequired={isRequired}
        label={`Edit ${label}`}
      >
        <ol className={styles.list}>
          {properties.map((property, index) => {
            const isChildPropertyConditional = property.id in conditionalMap;
            if (isChildPropertyConditional && !conditionalMap[property.id]) {
              return null;
            }

            const onChangeValue = (newPropertyValue: SimpleChartProperty['value']) => {
              // Hidden in this spread operator is the fact that we're preserving the
              // _internalListItemId that maintains the identity of each list item.
              const newItem = { ...item, [property.name]: newPropertyValue };
              const newKeyedValue = keyedValue || {};
              newKeyedValue[editingItemId] = newItem;

              updateConditionalMap(newItem);
              setInternalValue(Object.values(newKeyedValue));
            };

            return (
              <li className={styles.listItem} key={property.name}>
                <ControlFactory
                  props={{
                    ...createControlProps(property),
                    autoFocus: index === 0,
                    disabled,
                    hasEmptyValue: requiredChartProperties.isMissing(
                      { ...property, value: item[property.name] },
                      {
                        name,
                        interactionId,
                      },
                    ),
                    isRequired: requiredChartProperties.isRequired(name, property.name),
                    onChangeValue,
                    onError,
                    readOnly: disabled,
                    value: item[property.name] ?? getEmptyValueForChartProperty(property.type),
                  }}
                  type={property.type}
                />
              </li>
            );
          })}
        </ol>
      </LabeledFormControl>
      <div className={styles.footer}>
        <IconButton
          classes={{ root: styles.iconButton }}
          data-testid="list-control-check"
          disabled={disabled}
          onClick={onConfirm}
        >
          <PlaylistAddCheckIcon />
        </IconButton>
        <IconButton
          classes={{ root: styles.iconButton }}
          data-testid="list-control-delete"
          disabled={disabled}
          onClick={onDelete}
        >
          <SvgIcon component={DeleteIcon} />
        </IconButton>
      </div>
      {deleteDialog && (
        <ConfirmDialog
          container={containerRef.current}
          isDestructive
          onCancel={() => setDeleteDialog(false)}
          onSubmit={doDelete}
          submitLabel="Delete"
        />
      )}
    </div>
  );
};
