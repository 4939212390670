import { observer } from 'mobx-react';
import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/common/ui/components';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const Login: React.FC<{
  rootStore: RootStore;
}> = ({ rootStore }) => {
  const { auth: authStore } = rootStore;

  return (
    <div data-testid="login">
      <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>Welcome to Boulder Admin</DialogTitle>
        <DialogContent>Please log in with your Okta account.</DialogContent>

        <DialogActions>
          <form action={authStore.loginUrl} data-testid="login-form-okta" method="POST">
            <DialogActions>
              <Button
                color="primary"
                data-testid="login-button-okta"
                type="submit"
                variant="contained"
              >
                Login with Okta
              </Button>
            </DialogActions>
          </form>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default inject<typeof Login>('rootStore')(observer(Login));
