import React from 'react';

import Address from 'src/components/general/address';
import { TIMEZONES } from 'src/util/timezones';

export const timezones = TIMEZONES.map(tz => tz.label);

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const substances = [
  'Alcohol',
  'Alprazolam (Xanax)',
  'Amphetamine (Adderall)',
  'Clonzaepam (Klonopin)',
  'Cocaine',
  'Ecstasy/MDMA/Molly',
  'Fentanyl',
  'Flunitrazepam (Rohypnol)',
  'GHB (Gamma-Hydroxybutyric Acid)',
  'Heroin',
  'Hydrocodone (Norco, Vicodin, Lorcet)',
  'Hydromorphone (Dilaudid)',
  'Ketamine (Ketalar)',
  'Khat',
  'Kratom',
  'LSD (Lysergic Acid Diethylamide)',
  'Marijuana & Cannabinoids',
  'Mescaline/Peyote',
  'Methamphetamine',
  'Methylphenidate (Ritalin, Concerta, Daytrana)',
  'Morphine',
  'Nicotine',
  'Opium',
  'Oxycodone (Oxycontin, Roxicodone, Oxaydo, Percocet)',
  'PCP (Phencyclidine)',
  'Promethazine',
  'Psilocybin Mushrooms',
  'Steroids',
  'U-47700',
];

export const payorTypes = [
  'Self-pay',
  'Other Non-Federal Programs',
  'Preferred Provider Organization (PPO)',
  'Exclusive Provider Organization (EPO)',
  'Indemnity Insurance',
  'Health Maintenance Organization (HMO) Medicare Risk',
  'Blue Cross/Blue Shield',
  'CHAMPUS',
  'Commercial Insurance Co.',
  'Health Maintenance Organization',
  'Medicare Part A',
  'Medicare Part B',
  'Medicaid',
  'Other Federal Program',
  'Veterans Affairs Plan',
  'Workers Compensation Health Claim',
];

export const address = {
  type: 'object',
  renderer: {
    component: ({ model, key }) => <Address address={model[key]} displayInline />,
  },
  properties: {
    street1: { type: 'string' },
    street2: { type: 'string' },
    city: { type: 'string' },
    state: {
      type: 'string',
      enum: states,
    },
    zip: { type: 'string' },
  },
};

export const provider = {
  type: ['null', 'object'],
  autocomplete: true,
  uniforms: {
    labelFn: option => `${option.firstName} ${option.lastName}`,
    valueFn: option => option.id,
    source: 'searchProviders',
  },
  renderer: {
    component: ({ model, key }) => (
      <>
        {model[key].firstName} {model[key].lastName}
      </>
    ),
  },
};

export const relationships = [
  { name: 'Self', key: '18' },
  { name: 'Spouse', key: '1' },
  { name: 'Mother', key: '32' },
  { name: 'Father', key: '33' },
  { name: 'Cadaver Donor', key: '40' },
  { name: 'Child', key: '19' },
  { name: 'Dependent of minor dependent', key: '24' },
  { name: 'Emancipated minor', key: '36' },
  { name: 'Employee', key: '20' },
  { name: 'Foster child', key: '10' },
  { name: 'Grandchild', key: '5' },
  { name: 'Grandparent', key: '4' },
  { name: 'Handicapped dependent', key: '22' },
  { name: 'Injured plaintiff', key: '41' },
  { name: 'Life Partner', key: '53' },
  { name: 'Natural Child, insured does not have financial responsibility', key: '43' },
  { name: 'Niece or nephew', key: '5' },
  { name: 'Organ Donor', key: '39' },
  { name: 'Other Relationship', key: 'G8' },
  { name: 'Significant other', key: '29' },
  { name: 'Sponsored dependent', key: '23' },
  { name: 'Stepchild', key: '17' },
  { name: 'Unknown', key: '21' },
  { name: 'Ward of the court', key: '15' },
];

export const CARE_PLAN_OPTIONS = {
  weekly: 'Weekly',
  bi_weekly: 'Bi-weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
};

export const CLINICIAN_CARE_PLAN_OPTIONS = {
  ...CARE_PLAN_OPTIONS,
  other: 'Other',
  declined_care: 'Declined Clinical Care',
};

export const CARE_ADVOCATE_CARE_PLAN_OPTIONS = {
  ...CARE_PLAN_OPTIONS,
  bi_annually: 'Bi-annually',
  annually: 'Annually',
  n_a: 'N/A',
  other: 'Other',
};

export const PEER_COACH_CARE_PLAN_OPTIONS = {
  ...CARE_PLAN_OPTIONS,
  other: 'Other',
  declined_care: 'Declined Coaching',
};

export const ENROLLMENT_STATUS_OPTIONS = {
  potential_participant: 'Potential Participant',
  not_enrolled: 'Never Enrolled',
  not_responding: 'Inactive',
  enrolled: 'Enrolled',
  paused: 'Paused: Reason Unknown',
  paused_inpatient: 'Paused: Inpatient Care',
  paused_incarceration: 'Paused: Incarceration',
  unenrolled: 'Unenrolled: Reason Unknown',
  unenrolled_ineligible_geography: 'Unenrolled: No Longer Eligible (geography)',
  unenrolled_ineligible_insurance: 'Unenrolled: No Longer Eligible (insurance)',
  unenrolled_cost: 'Unenrolled: Cost',
  unenrolled_stabilized: 'Unenrolled: Graduated / Stabilized',
  unenrolled_transferred: 'Unenrolled: Transferred to Other Type of Care',
  unenrolled_discharged: 'Unenrolled: Discharged',
  unenrolled_unhappy: 'Unenrolled: Unhappy with Boulder',
  deceased_sud_related: 'Deceased: SUD-related',
  deceased_non_sud_related: 'Deceased: Not SUD-related',
  deceased_unknown: 'Deceased: Unknown',
};

export const VOICEMAIL_CONSENT_OPTIONS = {
  unknown: 'Unknown',
  granted: 'Yes',
  not_granted: 'No',
};

export const TEAM_ROLE_OPTIONS = {
  clinician: 'Clinician',
  care_advocate: 'Care Advocate',
  peer_coach: 'Peer Coach',
  billing_specialist: 'Billing Specialist',
  case_manager: 'Case Manager',
  registered_nurse: 'Registered Nurse',
  enrollment_specialist: 'Enrollment Specialist',
  navigator: 'Navigator',
  other_staff: 'Other Staff',
};

export const INQUIRY_FORM_REQUEST_TYPES = [
  'Enrollment',
  'Referral',
  'Informational Call',
  'Upload',
  'Gifting Informational Call',
];

export const PAYMENT_TYPES = {
  premera: 'Premera Blue Cross Insurance',
  regence: 'Regence Blue Cross Insurance',

  // Not yet supported, but included anyways
  medicaid: 'Medicaid Insurance',
  other: 'Other Insurance',
  selfpay: 'Pay Out of Pocket',
  uninsured: 'Uninsured',
};
