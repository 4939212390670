import React, { useContext } from 'react';

import { DrugScreenData } from 'src/clinicalSnapshot/components/DrugScreenData';
import ProblemsData from 'src/clinicalSnapshot/components/ProblemsData';
import VisitData from 'src/clinicalSnapshot/components/VisitData';
import ZdTicketsData from 'src/clinicalSnapshot/components/ZdTicketsData';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import {
  CLINICAL_SNAPSHOT_PROBLEMS_DATA,
  CLINICAL_SNAPSHOT_VISIT_DATA,
  CLINICAL_SNAPSHOT_ZD_TICKETS_DATA,
} from 'src/featureFlags/currentFlags';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

interface ClinicalSnapshotArgs {
  patientId: string;
}
export const ClinicalSnapshot = ({ patientId }: ClinicalSnapshotArgs) => {
  const flags = useContext(FeatureFlagContext);
  return (
    <div style={{ maxWidth: 600 }}>
      <PageTitle>Clinical Snapshot</PageTitle>
      {flags[CLINICAL_SNAPSHOT_PROBLEMS_DATA] && <ProblemsData patientId={patientId} />}
      {flags[CLINICAL_SNAPSHOT_VISIT_DATA] && <VisitData patientId={patientId} />}
      <DrugScreenData patientId={patientId} />
      {flags[CLINICAL_SNAPSHOT_ZD_TICKETS_DATA] && <ZdTicketsData patientId={patientId} />}
    </div>
  );
};
