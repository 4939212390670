import { css } from '@emotion/react';

import { TypographyStyles } from 'src/common/ui/base';

export const dialogTitleCss = css`
  ${TypographyStyles.subheader2};
  margin-bottom: 0;
`;

export const dialogContentCss = css`
  ${TypographyStyles.body2};
`;
