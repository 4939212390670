import { addMinutes, format, parseISO } from 'date-fns';
import keyBy from 'lodash/keyBy';
import React from 'react';
import { Chart, ReactGoogleChartEvent } from 'react-google-charts';

import { requestTypeMap } from 'src/dropInClinic/RequestType';
import { ScheduleLogQueue, ScheduleMap } from 'src/dropInClinic/hooks/useScheduleLogs';

const transformScheduleMapToRows = (scheduleMap: ScheduleMap, queue: ScheduleLogQueue[]) => {
  const requestById = keyBy(queue, 'requestId');

  const rows = Object.values(scheduleMap).flatMap(({ blocks, providerName, remainingIntakes }) => {
    return blocks.map(block => {
      const { startTime, duration, requestId } = block;

      const alternateProvidersListHtml = `<ul>${(block.alternateProviders ?? [])
        .map(provider => `<li>${provider.providerName}</li>`)
        .join('')}</ul>`;
      const startTimeDate = new Date(startTime);
      const endTimeDate = addMinutes(startTimeDate, duration);

      if (requestId) {
        const request = requestById[requestId];
        const requestType = requestTypeMap[request.requestType];
        const tooltip = `
          <div class="tooltip-content">
            Request Type: ${requestType}<br />
            Request ID: ${requestId}<br />
            Patient: ${request.patient.firstName} ${request.patient.lastName}<br />
            Queued At: ${format(parseISO(request.queuedAt), 'p')}<br />
            Duration: ${duration} minutes<br />
            Bonuses: ${JSON.stringify(request.bonuses)}<br />
            Alternate Providers:${alternateProvidersListHtml}<br />
            Remaining Intakes: ${remainingIntakes}
          </div>
        `;

        return [providerName, requestType, tooltip, startTimeDate, endTimeDate];
      } else {
        const tooltip = `
          <div class="tooltip-content">
            Free time<br />
            Start: ${format(parseISO(startTime), 'p')}<br />
            End: ${format(addMinutes(parseISO(startTime), duration), 'p')}<br />
            Duration: ${duration} minutes<br />
            Remaining Intakes: ${remainingIntakes}
          </div>
        `;

        return [providerName, 'Free', tooltip, startTimeDate, endTimeDate];
      }
    });
  });

  // sort by provider name
  rows.sort((a, b) => a[0].localeCompare(b[0]));

  return rows;
};

const columns = [
  { type: 'string', id: 'providerName' },
  { type: 'string', id: 'requestType' },
  { type: 'string', role: 'tooltip' },
  { type: 'date', id: 'start' },
  { type: 'date', id: 'end' },
];

// Example of how to handle chart events.
// We could move this into the ScheduleTimeline component if we want to set component state based on the chart event data.
// https://www.react-google-charts.com/examples/advanced-interactions
export const chartEvents: ReactGoogleChartEvent[] = [
  {
    eventName: 'select',
    callback: ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 1) {
        const [selectedItem] = selection;
        const { row } = selectedItem;

        console.log('You selected:', {
          row,
          requestType: chartWrapper.getDataTable()?.getValue(row, 1),
        });
      }
    },
  },
];

const ScheduleTimeline = ({
  scheduleMap,
  queue,
}: {
  scheduleMap: ScheduleMap;
  queue: ScheduleLogQueue[];
}) => {
  const data = [columns, ...transformScheduleMapToRows(scheduleMap, queue)];

  // https://www.react-google-charts.com/components/chart
  return (
    <Chart
      chartType="Timeline"
      data={data}
      width="100%"
      height="600px"
      options={{ allowHtml: true }}
      chartEvents={chartEvents}
    />
  );
};

export default ScheduleTimeline;
