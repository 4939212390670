import { LOAD_CLINICAL_SNAPSHOT_VISIT_DATA } from 'src/clinicalSnapshot/queries/loadClinicalSnapshotVisitData.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { ClinicalSnapshotVisitData } from 'src/generated/gql/graphql';

type ClinicalSnapshotVisitResponse = {
  data?: NonNullable<ClinicalSnapshotVisitData>;
  error?: Error;
  loading: boolean;
};

export const useClinicalSnapshotVisitData = (patientId: string): ClinicalSnapshotVisitResponse => {
  const { data, error } = useTypedSWR([LOAD_CLINICAL_SNAPSHOT_VISIT_DATA, { patientId }]);

  return {
    data: data?.staff_getPatientVisits as ClinicalSnapshotVisitData,
    error,
    loading: !data && !error,
  };
};
