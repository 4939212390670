import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { generateAssessmentUrl } from 'src/events/domain/generateAssessmentUrl';
import { usePatientAssessments } from 'src/events/hooks/usePatientAssessments';
import { useStyles as usePreVisitDisplayStyles } from 'src/events/styles/preVisitDisplay.styles';
import Colors from 'src/nightingale/Colors';
import { getDaysAgo } from 'src/util/transformDate';

/**
 * List of completed tasks that contains valuable patient assessment info
 * to the provider
 */
const AssessmentNameMappings: Record<string, string> = {
  hrqol: 'HRQOL',
  phq9_fbpt: 'PHQ-9',
  social_background: 'Social hx',
  nutrition_fbpt: 'Nutrition hx',
};

interface PatientAssessmentsArgs {
  patientId: string | null | undefined;
  isVisitComplete?: boolean;
}

export const PatientAssessments = ({
  patientId,
  isVisitComplete = false,
}: PatientAssessmentsArgs) => {
  const styles = useStyles();
  const preVisitDisplayStyles = usePreVisitDisplayStyles();
  const { assessments } = usePatientAssessments(patientId);

  if (!patientId) return <></>;

  // Data unavailable when visit is completed, handle for now by
  // hiding this section if the  visit is already completed
  if (isVisitComplete) return <></>;
  return (
    <div className={preVisitDisplayStyles.sectionContainer}>
      <div className={preVisitDisplayStyles.sectionLabel}>
        Assessments completed since last clinical visit
      </div>
      <div className={styles.assessmentList}>
        {!assessments.length && <div>None</div>}
        {assessments.length > 0 && (
          <ul className={styles.assessmentList}>
            {assessments
              // only show completed assessments that are supported
              .filter(assessment => AssessmentNameMappings[assessment.type])
              .map(assessment => (
                <li key={assessment.assessmentId}>
                  <div data-testid={`assessment-${assessment.assessmentId}`}>
                    <a href={generateAssessmentUrl(assessment)} className={styles.assessmentName}>
                      {transformAssessmentName(assessment.type)}
                    </a>
                    {' - '}
                    <span className={styles.assessmentDate}>
                      {getDaysAgo(new Date(assessment.completedAt))}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

function transformAssessmentName(assessmentType: string): string {
  const name = AssessmentNameMappings[assessmentType];
  if (name) {
    return name;
  }

  // unknown assessments are unsupported and filtered out
  return 'Unknown';
}

const useStyles = makeStyles({
  header: {
    paddingBottom: '0px',
  },
  assessmentList: {
    marginTop: 5,
    marginBottom: 0,
  },
  assessmentName: {
    color: Colors.BlueSpruce,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%', // 16.8px
    textDecorationLine: 'underline',
  },
  assessmentDate: {
    color: '#212121',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%', // 16.8px
  },
});
