import { makeStyles, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React, { useEffect, useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { UNOBSTRUCTED_CHART_ELEMENT_SAVE_ERROR } from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartElementLabel } from 'src/nightingale/components/ChartElement/ChartElement.Label';
import { ChartElementSaveError } from 'src/nightingale/components/ChartElement/ChartElement.SaveError';
import { ChartElementSaveSpinner } from 'src/nightingale/components/ChartElement/ChartElement.SaveSpinner';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import ChartElement from 'src/nightingale/components/ChartElement/domain/ChartElement';
import type { ChartPropertyValueChange } from 'src/nightingale/components/ChartElement/types';
import { ChartProperty } from 'src/nightingale/components/ChartProperty/ChartProperty';
import { ConditionalContext } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import { ChartElement as TChartElement, AnyChartProperty } from 'src/nightingale/types/types';

/**
 * Styles
 */
const useStyles = makeStyles<Theme, { altEnabled?: boolean }>({
  cardContainer: {
    backgroundColor: Colors.White,
    borderRadius: 0,
    boxShadow: ({ altEnabled }) =>
      altEnabled ? `0 2px 8px 0 rgba(0, 0, 0, 0.1), inset 0 0 1px ${Colors.Stillwater}` : 'none',
    marginBottom: 10,
    overflow: 'visible',
    padding: 16,
  },
  cardContentContainer: {
    padding: 0,
    position: 'relative',
    '&:last-child': { paddingBottom: 0 },
  },
  chartPropertyContainer: {
    marginTop: 16,
  },
});

export type ChartElementEditorProps = {
  definition: TChartElement;
  focusChartElement: () => void;
  hasSaveError?: boolean;
  isSaving?: boolean;
  saveErrorMessage?: string;
  setPropertyValue: (name: string, cpv: ChartPropertyValueChange) => void;
  onValidationError: (name: string, hasValidationError: boolean) => void;
  altEnabled?: boolean;
};

export const ChartElementEditor: React.FC<ChartElementEditorProps> = ({
  definition,
  focusChartElement,
  hasSaveError,
  isSaving,
  onValidationError,
  saveErrorMessage,
  setPropertyValue,
  altEnabled,
}) => {
  const { label, parsedInlineHelp, properties } = definition;

  const styles = useStyles({ altEnabled });

  const chartContext = useContext(ChartContext);
  const requiredChartProperties = useRequiredChartProperties();
  const flags = useContext(FeatureFlagContext);
  const unobstructedChartElementSaveError = flags[UNOBSTRUCTED_CHART_ELEMENT_SAVE_ERROR];

  const element = new ChartElement(definition, chartContext, requiredChartProperties, flags);

  const { conditionalMap } = useContext(ConditionalContext);

  const shouldDisplayProperty = (property: AnyChartProperty): boolean => {
    return conditionalMap[property.id] ?? true;
  };

  const isPropertyDisabled = (property: AnyChartProperty, hasSaveErrorFlag = false): boolean => {
    return !!(
      property.disabled ||
      isSaving ||
      (hasSaveError && !hasSaveErrorFlag) ||
      property.readOnly
    );
  };

  useEffect(() => {
    // If the first chart property is disabled, focus the chart element.
    if (properties.length && isPropertyDisabled(properties[0])) {
      requestAnimationFrame(() => {
        focusChartElement();
      });
    }
  }, []);

  return (
    <Card classes={{ root: styles.cardContainer }} data-testid="chart-element-editor">
      <ChartElementLabel
        hasMissingRequiredFields={element.hasMissingRequiredProperties}
        labelType={element.labelType}
        label={label}
      />

      <CardContent classes={{ root: styles.cardContentContainer }}>
        {isSaving ? <ChartElementSaveSpinner /> : null}

        {parsedInlineHelp && <InlineHelp helpText={parsedInlineHelp} />}

        {!unobstructedChartElementSaveError && hasSaveError ? (
          <ChartElementSaveError message={saveErrorMessage} />
        ) : null}

        {properties.map((property, index) => {
          const displayProperty = shouldDisplayProperty(property);
          const disabled = isPropertyDisabled(property, unobstructedChartElementSaveError);

          return displayProperty ? (
            <div className={styles.chartPropertyContainer} key={property.name}>
              <ChartProperty
                {...property}
                afterMarkAsNone={() => {
                  requestAnimationFrame(() => {
                    focusChartElement();
                  });
                }}
                autoFocus={index === 0 && !disabled}
                disabled={disabled}
                onError={hasValidationError => onValidationError(property.name, hasValidationError)}
                setPropertyValue={cpv => setPropertyValue(property.name, cpv)}
              />
            </div>
          ) : null;
        })}
      </CardContent>
    </Card>
  );
};
