import { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { SHOW_SEE_A_PATIENT } from 'src/featureFlags/currentFlags';
import { Provider } from 'src/shared/stores/resource';
import useRootStore from 'src/stores/useRootStore';

export default function useShowSeePatientNowButton() {
  const featureFlags = useContext(FeatureFlagContext);

  const rootStore = useRootStore();
  const {
    auth: { user },
  } = rootStore;

  const isFeatureEnabled = featureFlags[SHOW_SEE_A_PATIENT];

  const isClinician = (user as Provider)?.teamRole === 'clinician';

  return isFeatureEnabled && isClinician;
}
