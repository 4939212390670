import Card from '@material-ui/core/Card/Card';
import { makeStyles } from '@material-ui/core/styles';
import startCase from 'lodash/startCase';
import React from 'react';

import { Colors } from 'src/common/ui/base';
import { BoulderProgressBar } from 'src/dropInClinic/completedVisits/BoulderProgressBar';
import { CompletedVisitStatisticItem } from 'src/dropInClinic/hooks/useCompletedVisitStatistics';
import { STATES_BY_ABBREVIATION } from 'src/util/usStates';

export const ProgressItem = ({ item }: { item: CompletedVisitStatisticItem }) => {
  const classes = useStyles();

  const {
    state,
    requestType,
    numVisitsLimit,
    numVisitsComplete,
    numVisitsInProgress,
    numCaseRateComplete,
  } = item;

  const visitsComplete = Number.isNaN(numVisitsComplete) ? 0 : Number(numVisitsComplete);
  const visitsInProgress = Number.isNaN(numVisitsInProgress) ? 0 : Number(numVisitsInProgress);
  const visitLimit = Number.isNaN(numVisitsLimit) ? 0 : Number(numVisitsLimit);

  const title = getProgressTitle(state, requestType);
  const visitLimitText = visitLimit ? `${visitsComplete} of ${visitLimit}` : visitsComplete;
  const inProgressText = `${visitsInProgress} in progress`;
  const maximumValue = visitLimit > 0 ? visitLimit : visitsComplete + visitsInProgress;

  // Even though the case rate number is available for every state/requestType combination,
  // we only want to display it for the global intake request type.
  const showCaseRateStat = state === 'global' && requestType === 'intake';

  return (
    <Card className={classes.cardContainer}>
      <span style={{ fontWeight: 'bold' }}>{title}</span>
      <BoulderProgressBar
        maximum={maximumValue}
        values={[
          { value: numVisitsComplete, color: Colors.Blue4 },
          { value: numVisitsInProgress, color: Colors.Peach3 },
        ]}
      />
      <div className={classes.cardBottomRow}>
        <span style={{ fontStyle: 'italic' }}>{visitLimitText}</span>
        {showCaseRateStat && (
          <div className={classes.caseRateDisplay}>
            <span style={{ fontWeight: 'bold', marginRight: 5 }}>Case rate:</span>
            <span style={{ fontStyle: 'italic' }}>{numCaseRateComplete} </span>
          </div>
        )}
      </div>
      <div className={classes.cardBottomRow}>
        <div>{inProgressText}</div>
      </div>
    </Card>
  );
};

export const CompletedVisitProgress = ({ data }: { data: CompletedVisitStatisticItem[] }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {data.map(item => (
        <ProgressItem item={item} />
      ))}
    </div>
  );
};

const getProgressTitle = (state: string, requestType: string) => {
  let stateName: string;
  if (state.toLowerCase() === 'global') {
    stateName = 'Global';
  } else {
    stateName = STATES_BY_ABBREVIATION[state];
  }

  const formattedRequestType = startCase(requestType);

  return `${stateName} ${formattedRequestType}`;
};

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    marginBottom: 24,
    maxWidth: 1000,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    margin: 12,
    padding: 16,
    '& .MuiLinearProgress-root': {
      height: 20,
      borderRadius: 4,
    },
  },
  cardBottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  caseRateDisplay: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 2, // visually aligns the case rate number with edge of the progress bar
  },
});
