import { css } from '@emotion/react';

import { Colors } from 'src/common/ui/base';

export const badgeCss = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${Colors.Blue3};
  gap: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: ${Colors.Gray6};

  &.interactive {
    cursor: pointer;
    background: transparent;
  }

  &.rounded {
    border-radius: 12px;
    padding: 2px 8px;
  }

  &.warning,
  &.warning.interactive {
    border-color: var(--mui-palette-warning-main);
    color: var(--mui-palette-warning-main);
    background-color: var(--mui-palette-warning-light);
  }

  &.error,
  &.error.interactive {
    border-color: var(--mui-palette-error-main);
    color: var(--mui-palette-error-main);
    background-color: var(--mui-palette-error-light);
  }

  &.success,
  &.success.interactive {
    border-color: var(--mui-palette-success-main);
    color: var(--mui-palette-success-main);
    background-color: var(--mui-palette-success-light);
  }
`;
