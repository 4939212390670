/**
 * The primary source of truth for these colors is Figma. Please don't change
 * or add values not in Figma without consulting the design team.
 *
 * @src {@link https://figma.com/design/J2hlcm5TFWuXmbu7Sqi9pA/?node-id=13-10&t=l2wqus48K5WonT8b-0}
 */
export enum Colors {
  White = '#FFFFFF',

  Blue1 = '#E8F4FA',
  Blue2 = '#D1E2EB',
  Blue3 = '#AEC5D4',
  Blue4 = '#507796',
  Blue5 = '#003057',
  Blue6 = '#002441',

  Ice = '#F0F6F7',
  Mist = '#DCE6EB',
  StormCloud = '#CED8E0',
  Stillwater = '#98BECE',
  BlueSpruce = '#466C8A',
  Ocean = '#335979',

  Peach1 = '#FAEDE8',
  Peach2 = '#FFDBCD',
  Peach3 = '#F5BEA9',
  Peach4 = '#ED9D7B',
  Peach5 = '#A45014',
  Peach6 = '#543B28',

  Gray1 = '#FAF2EB',
  Gray2 = '#F0E5DD',
  Gray3 = '#D6CABF',
  Gray4 = '#A89A8D',
  Gray5 = '#696158',
  Gray6 = '#3D3934',

  Almond = '#FAF0E8',
  ToastedAlmond = '#EBDCD1',

  CoolGray1 = '#F2F4F5',
  CoolGray2 = '#E6E9EB',
  CoolGray3 = '#D2D6D9',
  CoolGray4 = '#ABAFB2',
  CoolGray5 = '#999DA1',
  CoolGray6 = '#7E848A',
  CoolGray7 = '#596066',
  CoolGray8 = '#363E45',
  CoolGray9 = '#272B2E',

  Green1 = '#E6F2EC',
  Green2 = '#005C2E',

  InfoBlue1 = '#F2F7FC',
  InfoBlue2 = '#0047B0',

  Yellow1 = '#FDF8E0',
  Yellow2 = '#775F00',

  Red1 = '#F9E6E8',
  Red2 = '#9E0012',

  Coral1 = '#F9E7E8',
  Coral2 = '#EC5756',
}

export default Colors;
