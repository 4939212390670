import { Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';

// eslint-disable-next-line camelcase
import type { Staff_GetEventCardsQuery } from 'src/generated/gql/graphql';
import AcceptSeePatientSuggestionButton from 'src/myDayToday/components/AcceptSeePatientSuggestionButton';
import { getSeePatientDisabledReason } from 'src/myDayToday/domain/getSeePatientDisabledReason';

type SeePatientNowButtonProps = {
  // eslint-disable-next-line camelcase
  eventCardsData?: Staff_GetEventCardsQuery;
};

/**
 * Button to accept an alternative request from the pop in queue. An alternative request is one that:
 *   - is for a follow-up visit
 *   - can be accepted by the provider
 *   - is not already being suggested next to the provider
 */
export const SeePatientNowButton = ({ eventCardsData }: SeePatientNowButtonProps) => {
  const styles = useStyles();

  const seePatientDisabledReason = getSeePatientDisabledReason(eventCardsData);
  const seePatientRequestId =
    eventCardsData?.staff_myDayTodayEventCards?.alternativeDropInCards?.[0]?.requestId;

  return (
    <Tooltip title={seePatientDisabledReason || ''}>
      <div className={styles.seePatientButtonWrapper}>
        {/* Buttons in a disabled state block onHover events from making their way up into the tooltip component
            so we add an extra div here to make the tooltip work correctly, even when the button is disabled. */}
        <AcceptSeePatientSuggestionButton
          disabled={!!seePatientDisabledReason}
          requestId={seePatientRequestId}
        />
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  seePatientButtonWrapper: {
    width: 'fit-content',
  },
});
