/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';

import { Colors, Spacing, TypographyStyles } from 'src/common/ui/base';

const sectionHeaderCss = css`
  ${TypographyStyles.subheader2}
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  color: ${Colors.CoolGray7};
  margin-bottom: ${Spacing.Unit3}px;

  &::after {
    border-bottom: 1px solid ${Colors.CoolGray5};
    content: '';
    display: flex;
    flex-grow: 1;
    margin-left: ${Spacing.Unit2}px;
  }
`;

/**
 * Section header / horizontal ruler.
 *
 * @param component - The HTML component level of the header.
 */
export const SectionHeader = ({
  children,
  component = 'h3',
}: React.PropsWithChildren<{
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}>) =>
  jsx(
    component,
    { css: sectionHeaderCss, 'data-testid': 'nds-component-section-header' },
    children,
  );
