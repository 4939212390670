import { SvgIcon, makeStyles } from '@material-ui/core';
import { format } from 'date-fns-tz';
import { inject, observer } from 'mobx-react';
import React from 'react';

// eslint-disable-next-line camelcase
import type { Staff_GetEventCardsQuery } from 'src/generated/gql/graphql';
import { SeePatientNowButton } from 'src/myDayToday/components/SeePatientNowButton';
import { getMotivationalQuote } from 'src/myDayToday/domain/motivationalPatientQuotesGenerator';
import useShowSeePatientNowButton from 'src/myDayToday/hooks/useShowSeePatientNowButton';
import { ReactComponent as IndividualIcon } from 'src/shared/icons/iconIndividual.svg';
import useRootStore from 'src/stores/useRootStore';
import { BoulderColors } from 'src/util/brand';

const TopBarComponent = ({
  currentTime,
  eventCardsData,
}: {
  currentTime: Date;
  // eslint-disable-next-line camelcase
  eventCardsData?: Staff_GetEventCardsQuery;
}) => {
  const styles = useStyles();
  const rootStore = useRootStore();
  const { provider } = rootStore.providers;

  const showSeePatientNowButton = useShowSeePatientNowButton();

  const { quote, author } = getMotivationalQuote();

  return (
    <div className={styles.topBar}>
      <div className={styles.leftSection}>
        <div className={styles.welcome}>{getGreeting(currentTime, provider)}</div>
        <div className={styles.timeDetails}>
          <div className={styles.timeDisplay}>
            <div className={styles.time}>{format(currentTime, 'h:mm a z')}</div>
            <div className={styles.date}>{format(currentTime, 'EEEE, MMMM d')}</div>
          </div>
        </div>
      </div>
      <div className={styles.middleSection}>
        {showSeePatientNowButton && <SeePatientNowButton eventCardsData={eventCardsData} />}
      </div>
      <div className={styles.rightSection}>
        <SvgIcon component={IndividualIcon} style={{ width: 48, height: 64 }} viewBox="0 0 48 64" />
        <blockquote>
          <p>“{quote}”</p>
          <footer>{author ? `- ${author}, Boulder Care Patient` : '- Boulder Care Patient'}</footer>
        </blockquote>
      </div>
    </div>
  );
};

const getGreeting = (
  date: Date,
  provider: { preferredName?: string | null; firstName?: string | null } | null,
): string => {
  let greeting: string;

  const hour = date.getHours();
  if (hour < 12) {
    greeting = 'Good morning';
  } else if (hour >= 12 && hour < 17) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }

  const name = provider?.preferredName ?? provider?.firstName;
  if (name) {
    greeting = greeting.concat(' ', name);
  }

  return greeting.concat('!');
};

const useStyles = makeStyles({
  topBar: {
    backgroundColor: BoulderColors.Gray2,
    padding: '34px 46px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    boxSizing: 'border-box',

    fontFamily: '"Nunito", "Nunito Sans"',

    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 150,
    height: 150,
  },
  welcome: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.201,
    lineHeight: '145%',
    color: BoulderColors.Blue4,
  },
  timeDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 100,
    color: BoulderColors.Gray5,
  },
  timeDisplay: {
    display: 'flex',
    flexDirection: 'column',
  },
  time: {
    fontSize: 16,
    lineHeight: '145%',
    letterSpacing: 0.201,
  },
  date: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '145%',
    letterSpacing: 1.44,
    textTransform: 'uppercase',
  },
  leftSection: {
    flex: 25,
  },
  middleSection: {
    flex: 45,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'end',
  },
  rightSection: {
    display: 'flex',
    flex: 30,
    flexDirection: 'row',
    gap: 16,
    color: BoulderColors.Gray5,
    maxWidth: 500,

    '& blockquote': {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      padding: 0,

      fontSize: 16,
      fontWeight: 600,
      lineHeight: '145%',
      letterSpacing: 0.201,
    },

    '& p': {
      padding: 0,
      margin: 0,
    },

    '& footer': {
      textTransform: 'uppercase',
      alignSelf: 'flex-end',

      fontSize: 12,
      fontWeight: 600,
      lineHeight: '145%',
      letterSpacing: 1.44,
    },
  },
});

export const TopBar = inject('rootStore')(observer(TopBarComponent));
