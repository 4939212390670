/** @jsxImportSource @emotion/react */
import MuiDialog, { type DialogProps } from '@mui/material/Dialog';
import MuiDialogActions, { type DialogActionsProps } from '@mui/material/DialogActions';
import MuiDialogContent, { type DialogContentProps } from '@mui/material/DialogContent';
import MuiDialogTitle, { type DialogTitleProps } from '@mui/material/DialogTitle';
import React from 'react';

import { dialogTitleCss, dialogContentCss } from 'src/common/ui/components/Dialog/Dialog.css';

export const Dialog = ({
  children,
  open,
  fullWidth,
  maxWidth,
}: Pick<DialogProps, 'children' | 'open' | 'fullWidth' | 'maxWidth'>) => {
  return (
    <MuiDialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        elevation: 2,
      }}
    >
      {children}
    </MuiDialog>
  );
};

export const DialogActions = ({ children }: Pick<DialogActionsProps, 'children'>) => {
  return <MuiDialogActions>{children}</MuiDialogActions>;
};

export const DialogContent = ({ children }: Pick<DialogContentProps, 'children'>) => {
  return <MuiDialogContent css={dialogContentCss}>{children}</MuiDialogContent>;
};

export const DialogTitle = ({ children }: Pick<DialogTitleProps, 'children'>) => {
  return <MuiDialogTitle css={dialogTitleCss}>{children}</MuiDialogTitle>;
};
