import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import React from 'react';

import { EventTiming } from 'src/myDayToday/components/getScheduledEventTiming';

export const ScheduledEventCardHeader = (props: {
  currentTime: Date;
  start: Date;
  timing: EventTiming;
}) => {
  const minuteDifference = Math.abs(differenceInMinutes(props.start, props.currentTime));
  const secondDifference = Math.abs(differenceInSeconds(props.start, props.currentTime));
  const timeDelta = minuteDifference < 1 ? `${secondDifference} sec` : `${minuteDifference} min`;

  if (props.timing === EventTiming.Imminent) {
    return (
      <div>
        Starts <span className="time">now</span>
      </div>
    );
  }

  if (props.timing === EventTiming.Late) {
    return (
      <div>
        Started <span className="time">{timeDelta} ago</span>
      </div>
    );
  }

  return (
    <div>
      Starts in <span className="time">{timeDelta}</span>
    </div>
  );
};
