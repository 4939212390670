import { createMatchingRouterState, useRouterStore } from 'mobx-state-router';

export const useProviderId = (): string | undefined => {
  const router = useRouterStore();
  const currentRoute = router.getCurrentRoute();
  const currentState = createMatchingRouterState(
    window.location,
    currentRoute ? [currentRoute] : [],
  );
  return currentState?.params?.id;
};
