import { reaction } from 'mobx';
import { HistoryAdapter, RouterState, routerStateToUrl } from 'mobx-state-router';

export class StaffHistoryAdapter extends HistoryAdapter {
  private destroyReaction?: () => void;

  private referrer?: string;

  /**
   * This method watches for changes to the URL and calls the callback function whenever the URL
   * changes.
   *
   * IMPORTANT: This method is designed to only be called once per instance of StaffHistoryAdapter!
   * @param callback
   */
  watchUrlChanges = (
    callback: (newPath: string, oldPath: string, newUrl: string, referrerUrl: string) => void,
  ) => {
    if (this.destroyReaction) {
      throw new Error('Only one observer can be added to the history object at a time.');
    }

    this.destroyReaction = reaction(
      () => this.routerStore.routerState,
      (routerState: RouterState, prevState: RouterState) => {
        const currentPath = routerStateToUrl(this.routerStore, routerState);
        const prevPath = routerStateToUrl(this.routerStore, prevState);
        if (currentPath !== prevPath) {
          callback(
            currentPath,
            prevPath,
            window.location.href,
            this.referrer || window.location.href,
          );
          this.referrer = this.referrer ? document.referrer : window.location.href;
        }
      },
    );
  };

  unwatchUrlChanges = () => {
    if (this.destroyReaction) {
      this.destroyReaction();
      this.destroyReaction = undefined;
    }
  };
}
