import { css } from '@emotion/react';

import { Colors } from 'src/common/ui/base/Colors';
import { TypographyStyles } from 'src/common/ui/base/Typography';

export const inputCss = css`
  color: ${Colors.CoolGray9};

  &.Mui-disabled {
    cursor: not-allowed;

    &::before,
    &::after {
      border-bottom-style: solid;
      border-bottom-color: ${Colors.Gray2};
    }
  }

  &:not(.Mui-disabled, .Mui-error) {
    &::before,
    &::after {
      border-bottom-color: ${Colors.Gray2};
    }

    &:hover {
      &::before,
      &::after {
        border-bottom-color: var(--mui-palette-primary-main);
      }
    }
  }
`;

export const labelCss = css`
  ${TypographyStyles.subheader2};
  font-size: 10px;
  color: ${Colors.CoolGray8};
  transform: scale(1);

  &.Mui-error {
    color: var(--mui-palette-error-main);
  }
`;

export const tooltipCss = css`
  background-color: ${Colors.CoolGray8};
`;

export const formHelperCss = css`
  ${TypographyStyles.body3};
  color: ${Colors.CoolGray7};
`;

export const chipCss = css`
  background-color: ${Colors.CoolGray2};

  .MuiSvgIcon-root {
    fill: ${Colors.CoolGray5};
  }
`;
