import { Theme } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import {
  addHours,
  differenceInMinutes,
  format,
  isEqual,
  isSameDay,
  parseISO,
  setHours,
  startOfHour,
  subMinutes,
} from 'date-fns';
import { inject, observer } from 'mobx-react';
import type { Instance, ModelCreationType, SnapshotOut } from 'mobx-state-tree';
import React, { useState } from 'react';
import { Calendar } from 'react-big-calendar';
import type { Components, View, ViewsProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { createFormats } from 'src/calendars/formats';
import { createLocalizer } from 'src/calendars/localizer';
import RescheduleEventForm from 'src/components/forms/resources/rescheduleEvent';
import Agenda, { AgendaPropsWithStatics } from 'src/components/pages/pageElements/agenda';
import CalendarEvent from 'src/components/pages/pageElements/calendarEvent';
import EventPopup from 'src/components/pages/pageElements/eventPopup';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import {
  EVENT_STATUSES,
  UNSCHEDULED_EVENT_STATUSES,
  getStartTime,
  getEndTime,
} from 'src/shared/util/events';
import CalendarStore from 'src/stores/calendar';
import { DEFAULT_VISIT_DURATION } from 'src/stores/events';
import type { EventInstance } from 'src/stores/models/event';
import type { RootStore } from 'src/stores/root';
import { colors as calendarColors, getEventStyles } from 'src/util/calendar';
import { parseUnknownDate } from 'src/util/parseUnknownDate';
import { localZone } from 'src/util/timezones';

const CALENDAR_EVENT_INCREMENT = 30;
const TIMESLOTS_PER_HOUR = 60 / CALENDAR_EVENT_INCREMENT;

/* @ts-expect-error types for react-big-calendar not maintained: https://github.com/jquense/react-big-calendar/issues/1332#issuecomment-494043469 */
const DnDCalendar = withDragAndDrop(Calendar);

// TODO: This ought to be exported by the store, but typifying it isn't simple
type CalendarStoreInstance = Instance<typeof CalendarStore>;

interface EventCalendarProps {
  calendar: CalendarStoreInstance;
  events: EventInstance[];
  actionTooltip: string;
  onCreate: (options: Partial<ModelCreationType<EventInstance>>) => void;
  showPatientColumn: boolean;
  title: string;
  views?: ViewsProps;
  showCheckbox?: boolean;
  eventPropGetter?: (event: EventInstance) => { style?: Record<string, unknown> };
  slotPropGetter?: (rawSlotTime: Date) => {
    style?: Record<string, unknown>;
  };
  titleAccessor: (event: EventInstance) => string;
  tooltipAccessor: (event: EventInstance) => string;
  onSave: (event: EventInstance, updates: Partial<EventInstance>) => void;
  rootStore: RootStore;
  className?: string;
  timezone?: string;
  fallbackTimezone?: string;
  classes: Record<string, string>;
  showMainCalendarIcon?: boolean;
  showToolBar?: boolean;
  timeToScrollTo?: Date;
}

const EventCalendar: React.VFC<EventCalendarProps> = ({
  calendar,
  events,
  actionTooltip,
  onCreate,
  showPatientColumn,
  title,
  views = { month: true, week: true, day: true, agenda: Agenda as AgendaPropsWithStatics },
  showCheckbox,
  eventPropGetter,
  slotPropGetter,
  titleAccessor,
  tooltipAccessor,
  onSave,
  rootStore,
  className,
  timezone,
  fallbackTimezone = localZone,
  showMainCalendarIcon = false,
  showToolBar = true,
  timeToScrollTo,
  classes,
}) => {
  const [selected, setSelected] = useState<SnapshotOut<EventInstance> | null>(null);
  const [showRescheduleModalFor, setShowRescheduleModalFor] =
    useState<SnapshotOut<EventInstance> | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<{ top: number; left: number } | null>(
    null,
  );
  const [selectedElement, setSelectedElement] = useState(null);
  const [hasClearedSnackbarAlert, setHasClearedSnackbarAlert] = useState<boolean>(false);
  const clearSnackbarAlert = () => setHasClearedSnackbarAlert(true);
  // Determine what timezone to use for the calendar
  const tz = timezone ?? fallbackTimezone;
  const localizer = createLocalizer(tz);

  // Helper function so we can retrieve the latest mobx object for
  // the event we want to act on
  const getMobxEventById = itemId => {
    return calendar.instanceById(itemId);
  };

  const handleItemSave = updatedItem => {
    // Changes may be saved without closing the modal, so don't remove
    // the selection here
    if (selected && onSave) {
      onSave(getMobxEventById(selected.id), updatedItem);
    }
  };

  const onSelectEvent = (item, evt) => {
    // This prevents the user from interacting with events that are
    // generated on the client side to represent pop-in visits
    if (!item.id) {
      return;
    }

    setSelected(getMobxEventById(item.id));
    setSelectedElement(evt.currentTarget);
  };

  const onSelectEventPositioned = (item, evt) => {
    // This prevents the user from interacting with events that are
    // generated on the client side to represent pop-in visits
    if (!item.id) {
      return;
    }

    setSelected(getMobxEventById(item.id));
    setSelectedPosition({ left: evt.clientX - 2, top: evt.clientY - 2 });
  };

  const onEventChange = ({ event, start, end }) => {
    // This prevents the user from interacting with events that are
    // generated on the client side to represent pop-in visits
    if (!event.id) {
      return;
    }

    const originalEvent = getMobxEventById(event.id);

    const { duration, finalStart } = calculateDurationAndStart(
      event,
      start,
      end,
      originalEvent.allDay,
    );

    if (isEqual(finalStart, parseUnknownDate(event.start)) && duration !== event.duration) {
      // If only the duration changed, edit the existing event's duration without rescheduling
      // Note that the UI doesn't allow you to only change the duration of an all day event,
      // so we can assume this is not an all day event.
      rootStore.events.updateEventAndSave(originalEvent, { duration });
    } else {
      setShowRescheduleModalFor({ ...event, start: finalStart, duration });
    }
  };

  const onView = view =>
    rootStore.routerStore.goTo(rootStore.routerStore.routerState.routeName, {
      params: rootStore.routerStore.routerState.params,
      queryParams: {
        date: calendar.currentDateQueryParam,
        view,
      },
    });

  const onNavigate = (date: Date) =>
    rootStore.routerStore.goTo(rootStore.routerStore.routerState.routeName, {
      params: rootStore.routerStore.routerState.params,
      queryParams: {
        view: calendar.currentView,
        date: format(date, 'yyyy-MM-dd'),
      },
    });

  const onCanceledCheckboxClick = () => {
    const { setShowCanceledRescheduledEvents, showCanceledRescheduledEvents } = calendar;

    setShowCanceledRescheduledEvents(!showCanceledRescheduledEvents);
  };

  const onAllDayCheckboxClick = () => {
    const { setShowAllDayEvents, showAllDayEvents } = calendar;
    setShowAllDayEvents(!showAllDayEvents);
  };

  const handleOpenVideoConference = async item => {
    rootStore.events.openVideoConverence(getMobxEventById(item.id));
  };

  const onClosePopup = async updates => {
    setSelected(null);
    setSelectedPosition(null);

    if (selected && updates) {
      const originalEvent = getMobxEventById(selected.id);
      if (originalEvent) {
        rootStore.events.updateEventAndSave(originalEvent, updates);
      }
    }
  };

  const onCloseRescheduleModal = () => {
    setShowRescheduleModalFor(null);
  };

  const onSaveRescheduleModal = async updates => {
    const event = getMobxEventById(updates.id);
    await rootStore.events.rescheduleEvent(event, {
      scheduleChangeNotes: updates.scheduleChangeNotes,
      scheduleChangeReason: updates.scheduleChangeReason,
      start: updates.start,
      duration: updates.duration,
      timezone: updates.timezone,
    });
    setShowRescheduleModalFor(null);
  };

  const eventsToRender = () => {
    const { showCanceledRescheduledEvents, showAllDayEvents } = calendar;
    let toRender = events;
    if (!showCanceledRescheduledEvents) {
      toRender = toRender.filter(
        event => !event.status || !UNSCHEDULED_EVENT_STATUSES.includes(event.status),
      );
    }
    if (!showAllDayEvents) {
      toRender = toRender.filter(event => !event.allDay);
    }

    return toRender;
  };

  const createSelectSlotHandler = () => {
    const { currentView } = calendar;
    return select => {
      const options: { start?: Date; duration?: number; timezone?: string } = { timezone: tz };
      if (select.start) {
        let start = parseUnknownDate(select.start);
        if (currentView === 'month') {
          // Clicking on a day in the month view gives a start time of 00:00:00,
          // so instead we'll initialize to the next hour if today was clicked,
          // otherwise to 8am
          if (isSameDay(start, new Date())) {
            start = startOfHour(addHours(new Date(), 1));
          } else {
            start = startOfHour(setHours(start, 8));
          }
        } else if (select.action === 'click') {
          options.duration = DEFAULT_VISIT_DURATION;
        }
        options.start = start;
      }
      return onCreate(options);
    };
  };

  // TODO - Calendar child components aren't mobx observers so don't get re-rendered unless
  // we explicitly dereference all the fields we care about to set up the watchers. I'm sure
  // there's a more graceful way, like using accessors that contain observers...
  events.forEach(i => [i.title, i.allDay, i.duration, i.start, i.status]);

  const calendarClasses = ['borderless-calendar', className ?? 'darkened-calendar'];

  // TODO: This could be more clever but not calculating this now.
  const defaultTimeToScrollTo = setHours(calendar.currentDate, 8);

  return (
    <div>
      <Snackbar
        action={[
          <IconButton color="inherit" onClick={clearSnackbarAlert} key="close">
            <CloseIcon />
          </IconButton>,
        ]}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        className={classes.snackbar}
        data-testid="timezone-snackbar-alert"
        message={
          <>
            <h3 className={classes.snackbarTitle}>Timezone Alert</h3>
            <p>
              This patient’s timezone has not been set. This calendar is displayed in your timezone.
              Set a timezone for this patient to see the calendar in their timezone.
            </p>
          </>
        }
        onClose={clearSnackbarAlert}
        open={!timezone && !hasClearedSnackbarAlert}
      />
      <TitleBar
        title={title}
        onAction={() => onCreate({})}
        actionTooltip={actionTooltip}
        actionIcon={<AddIcon />}
        showMainCalendarIcon={showMainCalendarIcon}
      />
      {showCheckbox && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={calendar.showCanceledRescheduledEvents}
                onChange={() => onCanceledCheckboxClick()}
              />
            }
            label="Show canceled and rescheduled events"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={calendar.showAllDayEvents}
                onChange={() => onAllDayCheckboxClick()}
              />
            }
            label="Show all day events"
          />
        </div>
      )}
      <DnDCalendar
        className={classNames(calendarClasses)}
        date={calendar.currentDate}
        view={calendar.currentView as View}
        scrollToTime={timeToScrollTo ?? defaultTimeToScrollTo}
        slotPropGetter={slotPropGetter}
        titleAccessor={titleAccessor}
        messages={{ agenda: 'Agenda' }}
        views={views}
        localizer={localizer}
        events={eventsToRender()}
        onEventDrop={onEventChange}
        onEventResize={onEventChange}
        selectable
        onSelecting={() => false}
        onSelectEvent={onSelectEvent}
        onSelectSlot={createSelectSlotHandler()}
        onView={onView}
        onNavigate={onNavigate}
        step={CALENDAR_EVENT_INCREMENT}
        timeslots={TIMESLOTS_PER_HOUR}
        toolbar={showToolBar}
        style={{
          // 250px is an approximation for the elements above the calendar in the viewport,
          // TODO: Flexbox is supposed to handle this
          height: 'calc(100vh - 250px)',
        }}
        tooltipAccessor={tooltipAccessor}
        startAccessor={getStartTime}
        endAccessor={getEndTime}
        formats={createFormats(tz)}
        components={{
          event: eventProps => <CalendarEvent currentView={calendar.currentView} {...eventProps} />,
          agenda: {
            // rowClickHandler is passed into our internal Agenda component; this seems to be an
            // undocumented API
            rowClickHandler: (event, evt) => onSelectEventPositioned(event, evt),
            showPatientColumn,
          } as Components['agenda'],
        }}
        eventPropGetter={eventPropGetter ?? getEventStyles(calendar.currentView)}
      />
      {!!selected && (
        <EventPopup
          calendar={calendar}
          item={selected}
          itemElement={selectedElement}
          position={selectedPosition}
          onClose={onClosePopup}
          onSave={handleItemSave}
          onOpenVideoConference={handleOpenVideoConference}
        />
      )}
      {showRescheduleModalFor && (
        <Dialog open maxWidth="md" fullWidth onClose={onCloseRescheduleModal}>
          <DialogTitle>Reschedule Event</DialogTitle>
          <DialogContent>
            <RescheduleEventForm
              disableDatepicker={showRescheduleModalFor.status === EVENT_STATUSES.RESCHEDULED}
              item={showRescheduleModalFor}
              onCancel={onCloseRescheduleModal}
              onSave={onSaveRescheduleModal}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

/**
 * Prevent an event's duration from becoming 0 or negative. Dragging an event up from the bottom resize lines can cause this.
 * @param event Updated event object.
 * @param initialStart Event start value determined by the calendar.
 * @param end Event end value determined by the calendar.
 * @param allDay Boolean that describes whether an event is an all day event.
 */
export function calculateDurationAndStart(
  event: EventInstance,
  start: string | Date,
  end?: string | Date,
  allDay?: boolean,
) {
  // If it's an all day event, we want to set the start time to the beginning of the day.
  let parsedStart = parseUnknownDate(start);
  if (allDay) {
    parsedStart = parseISO(`${format(parsedStart, 'yyyy-MM-dd')}T00:00:00.000Z`);
  }

  let duration = (event.duration ?? 0) <= 0 ? CALENDAR_EVENT_INCREMENT : event.duration;

  if (end) {
    const parsedEnd = parseUnknownDate(end);
    if (isEqual(parsedStart, parsedEnd)) {
      parsedStart = subMinutes(parsedStart, CALENDAR_EVENT_INCREMENT);
    } else {
      duration = differenceInMinutes(parsedEnd, parsedStart);
    }
  }

  return { duration, finalStart: parsedStart };
}

const styles: Styles<Theme, any> = () => ({
  '@global': {
    '.borderless-calendar': {
      '& .rbc-event-label': {
        display: 'none',
      },
      '& .rbc-time-view': {
        border: 'none',
      },
      '& .rbc-timeslot-group': {
        borderBottom: 'none',
        minHeight: 80,
      },
      '& .rbc-header + .rbc-header, .rbc-time-header-content, .rbc-time-content > * + * > *, .rbc-time-header-content .rbc-day-bg + .rbc-day-bg':
        {
          borderLeft: 'none',
        },
      '& .rbc-time-header-content .rbc-header': {
        textAlign: 'left',
        borderBottom: 'none',
      },
      '& .rbc-time-slot, .rbc-time-header.rbc-overflowing': {
        borderRight: 'none',
      },
      '& .rbc-time-column': {
        marginRight: 5,
      },
      '& .rbc-time-content': {
        padding: 5,
      },
      '& .rbc-day-slot .rbc-timeslot-group': {
        borderTop: `1px solid ${calendarColors.OFF_WHITE}`,
      },
      '& .rbc-event': {
        border: '1px solid',
      },
    },
    '.striped-calendar': {
      '& .rbc-day-slot': {
        background: `repeating-linear-gradient( -45deg, ${calendarColors.OFF_WHITE}, ${calendarColors.OFF_WHITE} 12px, ${calendarColors.LIGHT_GREY} 12px, ${calendarColors.LIGHT_GREY} 28px );`,
      },
    },
    '.darkened-calendar': {
      '& .rbc-day-slot .rbc-time-slot': {
        backgroundColor: calendarColors.MEDIUM_GREY,
        borderTop: 'none',
      },
    },

    // Override default calendar styles
    '.rbc-label': {
      fontSize: 12,
    },
    '.rbc-header': {
      fontWeight: 'normal',
    },
    '.rbc-today': {
      '&.rbc-now, &.rbc-header, &.rbc-day-bg': {
        backgroundColor: 'transparent',
      },
      '&.rbc-header': {
        fontWeight: 'bold',
      },
    },
    // This allows us to see the hover styling when
    // hovering over half hour blocks in the weekly/daily views
    '.rbc-events-container': {
      pointerEvents: 'none',
    },
    // This allows events on the weekly/daily views to still
    // get the expected hover styling/pointer behavior
    '.rbc-event': {
      pointerEvents: 'all',
    },
    '.rbc-agenda-view': {
      '& .rbc-agenda-content': {
        '& .rbc-agenda-event-cell': {
          padding: 0,
        },
      },
    },
  },
  snackbar: {
    '& .MuiPaper-root': {
      backgroundColor: '#F07562',
    },
  },
  snackbarTitle: {
    marginTop: 0,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(EventCalendar)));
