import { differenceInSeconds } from 'date-fns';

/**
 * Determines how "soon" an event is.
 * The order from "most future" to "most past" is:
 *   Future -> Soon -> Imminent -> Late
 */
export enum EventTiming {
  Future = 'future',
  Imminent = 'imminent',
  Late = 'late',
  Soon = 'soon',
}
export const getScheduledEventTiming = (currentTime: Date, start: Date) => {
  const secondDifference = differenceInSeconds(start, currentTime);

  // There is a 30 second window *both before and after* the start time,
  // so this gets top priority
  if (Math.abs(secondDifference) <= 30) {
    return EventTiming.Imminent;
  }

  if (start < currentTime) {
    return EventTiming.Late;
  }

  // If the event starts within 10 minutes, it's considered "soon"
  if (secondDifference <= 10 * 60) {
    return EventTiming.Soon;
  }

  return EventTiming.Future;
};
