import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import { link, listItem } from 'src/clinicalSnapshot/commonStyles';
import { ClinicalProblem } from 'src/clinicalSnapshot/hooks/useProblemList';
import { Colors } from 'src/common/ui/base';

const ProblemItem = ({ problem, patientId }: { patientId: string; problem: ClinicalProblem }) => {
  const styles = useProblemItemStyles();

  return (
    <li
      className={classNames({
        [styles.listItem]: true,
        [styles.flaggedProblem]: Boolean(problem.flagged),
      })}
      key={problem.title}
    >
      <a href={`/patients/${patientId}/overview#${problem.propertyName}`} className={styles.link}>
        {problem.title}
      </a>
      <div>{problem.flaggedNote}</div>
    </li>
  );
};

const useProblemItemStyles = makeStyles({
  flaggedProblem: {
    maxWidth: '540px',
    backgroundColor: Colors.Yellow1,
    border: `1px solid ${Colors.Yellow2}`,
    color: Colors.CoolGray9,
    margin: '8px 0',
  },
  link,
  listItem,
});

export default ProblemItem;
