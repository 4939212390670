import { makeStyles } from '@material-ui/core';
import React from 'react';

import RouteLink from 'src/components/general/routeLink';
import { PatientLookupResult } from 'src/stores/queries/userSearch';

export const OneDuplicateMessage = ({
  possibleDuplicates,
}: {
  possibleDuplicates: PatientLookupResult[];
}) => {
  const classes = useMessageStyles();
  const hasJustOneDuplicate = possibleDuplicates.length === 1;
  if (!hasJustOneDuplicate) return <></>;
  return (
    <>
      This chart has the same name and date of birth as an{' '}
      <RouteLink
        className={classes.link}
        newWindow={false}
        route="showPatient"
        routeParams={[{ id: possibleDuplicates[0].id }]}
      >
        existing patient.
      </RouteLink>
    </>
  );
};

export const MoreThanOneDuplicateMessage = ({
  possibleDuplicates,
}: {
  possibleDuplicates: PatientLookupResult[];
}) => {
  const classes = useMessageStyles();
  const hasMoreThanOne = possibleDuplicates.length > 1;
  if (!hasMoreThanOne) return <></>;
  return (
    <>
      This chart has the same name and date of birth as the following patients:{' '}
      <ul>
        {possibleDuplicates.map(dupe => (
          <li key={dupe.id}>
            <RouteLink
              className={classes.link}
              newWindow={false}
              route="showPatient"
              routeParams={[{ id: dupe.id }]}
            >
              {dupe.id}
            </RouteLink>
          </li>
        ))}
      </ul>
    </>
  );
};

const useMessageStyles = makeStyles({
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
});
