import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';

import { link } from 'src/clinicalSnapshot/commonStyles';
import DisplayData, { DisplayedData } from 'src/clinicalSnapshot/components/DisplayData';
import { useClinicalSnapshotVisitData } from 'src/clinicalSnapshot/hooks/useClinicalSnapshotVisitData';
import { SectionHeader } from 'src/common/ui/components';
import { TEAM_ROLE_OPTIONS } from 'src/components/forms/schemas/definitions';
import {
  ClinicalSnapshotVisitData,
  ClinicalVisitCadence,
  ClinicalSnapshotVisitDetails,
  Provider,
} from 'src/generated/gql/graphql';
import { labelForEventSubType } from 'src/shared/util/events';
import { getDaysAgo } from 'src/util/transformDate';

// expected format: 09-12-2024 (3 days ago)
const transformPastDateForSnapshotDisplay = (date: Date | null | undefined) => {
  if (!date) return '';
  return `${format(date, 'MM/dd/yyyy')} (${getDaysAgo(date)})`;
};

const getVisitTitle = (visitDetails: ClinicalSnapshotVisitDetails) => {
  if (!visitDetails) return 'Error: Missing visit data';

  const { providers } = visitDetails;

  const providerString =
    providers && providers.length > 0
      ? providers
          .filter(provider => !!provider && provider.teamRole)
          .map(
            ({ firstName, lastName, teamRole }: Provider) =>
              `${firstName} ${lastName} (${TEAM_ROLE_OPTIONS[teamRole as string]})`,
          )
          .join(', ')
      : 'Provider data not found';
  const visitTypeString = visitDetails.visitSubType
    ? `${labelForEventSubType(visitDetails.visitSubType)}`
    : 'Unknown visit type';
  return `${visitTypeString} with ${providerString}`;
};

const VisitLink = ({
  patientId,
  visitDetails,
}: {
  patientId: string;
  visitDetails: ClinicalSnapshotVisitDetails;
}) => {
  const styles = useStyles();

  if (!visitDetails) {
    return null;
  }
  const { visitDate, id } = visitDetails;
  return (
    <div>
      <div style={{ marginBottom: '8px' }}>
        <a className={styles.link} href={`/patients/${patientId}/events/${id}`}>
          {getVisitTitle(visitDetails)}
        </a>
      </div>
      <div>
        {visitDate
          ? transformPastDateForSnapshotDisplay(new Date(visitDate))
          : // Error case, if no date is found display message
            'Missing date'}
      </div>
    </div>
  );
};

const VisitCadence = ({ visitCadence }: { visitCadence: ClinicalVisitCadence | null }) => {
  if (!visitCadence) {
    return null;
  }
  const justification =
    visitCadence.justification === 'other'
      ? `other: ${visitCadence.otherDetails ?? 'No details given'}`
      : visitCadence.justification;
  return (
    <div>
      {visitCadence.visitCadence} {visitCadence.justification ? `(${justification})` : ''}
    </div>
  );
};

const buildVisitDataSchema = (patientId: string, visitData: ClinicalSnapshotVisitData) => {
  const visitDataSchema: DisplayedData[] = [];
  visitDataSchema.push({
    label: 'Visit Cadence',
    component: <VisitCadence visitCadence={visitData.visitCadence} />,
  });

  if (visitData.lastClinicalVisit) {
    visitDataSchema.unshift({
      label: 'Last Clinical Visit',
      component: <VisitLink visitDetails={visitData.lastClinicalVisit} patientId={patientId} />,
    });
  }

  if (visitData.bridgeVisit) {
    visitDataSchema.unshift({
      label: 'Bridge Visit',
      component: <VisitLink visitDetails={visitData.bridgeVisit} patientId={patientId} />,
    });
  }

  return visitDataSchema;
};

const VisitData = ({ patientId }: { patientId: string }) => {
  const styles = useStyles();
  const [visitDataSchema, setVisitDataSchema] = useState<DisplayedData[]>([]);
  const { data } = useClinicalSnapshotVisitData(patientId);

  useEffect(() => {
    if (data) {
      setVisitDataSchema(buildVisitDataSchema(patientId, data));
    }
  }, [data, patientId]);

  return (
    <div className={styles.sectionContainer}>
      <SectionHeader>Visits</SectionHeader>
      <DisplayData schema={visitDataSchema} />
    </div>
  );
};

export default VisitData;

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: '16px',
  },
  link,
});
