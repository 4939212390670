import { Tooltip } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';
import { BoulderColors } from 'src/util/brand';
import { STATES } from 'src/util/usStates';

type StateBadgeProps = {
  state: string;
  city?: string | null;
};

const getAbbreviation = (state: string) => STATES[state]?.abbreviation ?? state;

export const StateBadge = ({ city, state }: StateBadgeProps) => {
  const stateBadgeStyles = useStateBadgeStyles();
  const tooltipStyles = useTooltipStyles();
  const stateAbbr = getAbbreviation(state);

  return (
    <Tooltip
      title={
        <div className={stateBadgeStyles.tooltipContainer}>
          <div className="content">
            <LocationOn style={{ width: 18, height: 18 }} />
            <div>Location</div>
          </div>
          <div style={{ fontSize: 12 }}>{city ? `${city}, ${stateAbbr}` : stateAbbr}</div>
        </div>
      }
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <div className={stateBadgeStyles.stateBadge}>
        <LocationOn style={{ width: 18, height: 18 }} />
        {stateAbbr}
      </div>
    </Tooltip>
  );
};

const useStateBadgeStyles = makeStyles({
  stateBadge: {
    border: `1px solid #466C8A55`,
    color: BoulderColors.Gray6,
    borderRadius: 4,
    display: 'flex',
    padding: 3,
    gap: 3,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    lineHeight: '100%',
  },
  tooltipContainer: {
    padding: 8,
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    width: 240,

    '& .content': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      flexDirection: 'row',
      textTransform: 'uppercase',
      gap: 8,
      fontSize: 10,
    },
  },
});
