import isBefore from 'date-fns/isBefore';

// eslint-disable-next-line camelcase
import type { Staff_GetEventCardsQuery } from 'src/generated/gql/graphql';

export const ALREADY_ASSIGNED_TO_PATIENT = 'You are already assigned to a patient right now.';
export const NO_PATIENTS_IN_QUEUE =
  'There are no patients in the queue right now (that you are licensed and credentialed to see).';

export function getSeePatientDisabledReason(
  // eslint-disable-next-line camelcase
  data: Staff_GetEventCardsQuery | undefined,
): string | undefined {
  // If the nextVisit is being prepped (i.e. the provider is sitting around waiting), and we have an
  // alternative visit available, let the provider pick up that alternative if they want.
  const hasViableAlternativeToNextEvent =
    data?.staff_myDayTodayEventCards?.nextEvent?.prepInProgress &&
    data?.staff_myDayTodayEventCards?.alternativeDropInCards.length;

  const hasViableAssignedVisit =
    data?.staff_myDayTodayEventCards?.nextEvent && !hasViableAlternativeToNextEvent;

  const hasNextVisitStarted =
    data?.staff_myDayTodayEventCards?.nextEvent?.start &&
    isBefore(new Date(data.staff_myDayTodayEventCards.nextEvent.start), new Date());

  if (hasViableAssignedVisit && hasNextVisitStarted) {
    return ALREADY_ASSIGNED_TO_PATIENT;
  }

  if (!data?.staff_myDayTodayEventCards?.alternativeDropInCards.length) {
    return NO_PATIENTS_IN_QUEUE;
  }

  return undefined;
}
