import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import React, { createContext, useContext, useCallback, useMemo, useRef } from 'react';
import { KeyedMutator } from 'swr';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  QueryResult as AddFlowToInteractionQueryResult,
  ADD_FLOW_TO_INTERACTION,
} from 'src/nightingale/data/queries/addFlowToInteraction';
import { QueryResult as InteractionChartPropertyValuesQueryResult } from 'src/nightingale/data/queries/getChartPropertyValuesForInteraction';
import {
  QueryResult as RefreshFlowOnInteractionQueryResult,
  REFRESH_FLOW_ON_INTERACTION,
} from 'src/nightingale/data/queries/refreshFlowOnInteraction';
import {
  QueryResult as RemoveFlowFromInteractionQueryResult,
  REMOVE_FLOW_FROM_INTERACTION,
} from 'src/nightingale/data/queries/removeFlowFromInteraction';
import { refreshPatientSnapshotTime } from 'src/nightingale/data/refreshPatientSnapshotTime';
import { useSWRInteraction } from 'src/nightingale/data/useSWRInteraction';
import { AdditionalContext, Context } from 'src/nightingale/summarization/types';
import type {
  AnyChartProperty,
  FlowStep,
  Interaction,
  ChartPropertyWithValue,
} from 'src/nightingale/types/types';
import { InteractionKind } from 'src/nightingale/types/types';
import logger from 'src/shared/util/logger';

export type ChartInteractionState = {
  addFlowToInteraction: (flowId: string, parentFlowIndex?: number) => Promise<void>;
  context: Context | null;
  error: any;
  kind: InteractionKind | null;
  interaction: Interaction | null;
  interactionKey: string | null;
  interactionReferenceId: string | null;
  interactionValues: ChartPropertyWithValue[] | null;
  isLoading: boolean;
  mutateChartPropertyValues: KeyedMutator<InteractionChartPropertyValuesQueryResult>;
  patientId: string | null;
  properties: Record<string, AnyChartProperty> | null;
  refreshFlowOnInteraction: () => Promise<void>;
  refreshPatientSnapshot: () => Promise<void>;
  removeFlowFromInteraction: (flowId: string) => Promise<void>;
};

const initialState: ChartInteractionState = {
  addFlowToInteraction: () => Promise.resolve(),
  context: null,
  error: null,
  kind: null,
  interaction: null,
  interactionKey: null,
  interactionReferenceId: null,
  interactionValues: null,
  isLoading: true,
  mutateChartPropertyValues: () => Promise.resolve(undefined),
  patientId: null,
  properties: null,
  refreshFlowOnInteraction: () => Promise.resolve(),
  refreshPatientSnapshot: () => Promise.resolve(),
  removeFlowFromInteraction: () => Promise.resolve(),
};

/**
 * Provides context for a ChartInteraction
 */
export const ChartInteractionContext = createContext<ChartInteractionState>(initialState);

type ChartInteractionProviderProps = {
  additionalSummarizationContext?: Partial<AdditionalContext>;
  interactionKey: string;
  interactionReferenceId: string;
  kind?: InteractionKind;
  patientId: string;
};

const ChartInteraction: React.FC<ChartInteractionProviderProps> = ({
  additionalSummarizationContext,
  children,
  interactionKey,
  interactionReferenceId,
  kind = InteractionKind.Visit,
  patientId,
}) => {
  const { apolloClient } = useContext(ApolloClientContext);
  const isUpdating = useRef(false);

  const { data, error, isLoading, mutateChartPropertyValues, mutateInteraction } =
    useSWRInteraction({
      referenceId: interactionReferenceId,
      interactionKey,
      patientId,
      additionalSummarizationContext,
      kind,
    });

  const addFlowToInteraction = useCallback(
    async (flowId, parentFlowIndex) => {
      if (!flowId) return;

      if (isUpdating.current) {
        logger.warn(`Already updating flow, can't concurrently update.`);
        return;
      }
      isUpdating.current = true;

      try {
        if (!apolloClient) {
          throw new Error('Apollo client not found');
        }

        // Update values on the server
        await apolloClient.mutate<AddFlowToInteractionQueryResult>({
          mutation: ADD_FLOW_TO_INTERACTION,
          variables: { interactionReferenceId, flowId, parentFlowIndex },
        });

        // Update local cache
        await mutateInteraction();
      } catch (err) {
        logger.error(`Error adding flow to interaction: ${err}`);
        throw err;
      } finally {
        isUpdating.current = false;
      }
    },
    [mutateInteraction, interactionReferenceId, apolloClient],
  );

  const removeFlowFromInteraction = useCallback(
    async flowId => {
      if (!flowId) return;

      if (isUpdating.current) {
        logger.warn(`Already updating flow, can't concurrently update.`);
        return;
      }
      isUpdating.current = true;

      try {
        if (!apolloClient) {
          throw new Error('Apollo client not found');
        }

        // Update values on the server
        await apolloClient.mutate<RemoveFlowFromInteractionQueryResult>({
          mutation: REMOVE_FLOW_FROM_INTERACTION,
          variables: { interactionReferenceId, flowId },
        });

        // Update local cache
        await mutateInteraction();
      } catch (err) {
        logger.error(`Error removing flow from interaction: ${err}`);
        throw err;
      } finally {
        isUpdating.current = false;
      }
    },
    [mutateInteraction, interactionReferenceId, apolloClient],
  );

  const refreshPatientSnapshot = useCallback(async () => {
    if (!apolloClient) {
      throw new Error('Apollo client not found');
    }
    await refreshPatientSnapshotTime(apolloClient, interactionReferenceId);
  }, [apolloClient, interactionReferenceId]);

  const refreshFlowOnInteraction = useCallback(async () => {
    try {
      if (!apolloClient) {
        throw new Error('Apollo client not found');
      }

      // Update values on the server
      await apolloClient.mutate<RefreshFlowOnInteractionQueryResult>({
        mutation: REFRESH_FLOW_ON_INTERACTION,
        variables: { interactionReferenceId, interactionKey },
      });

      // Update local cache
      await mutateInteraction();
    } catch (err) {
      logger.error(`Error refreshing flow on interaction: ${err}`);
      throw err;
    }
  }, [apolloClient, mutateInteraction, interactionReferenceId, interactionKey]);

  const properties = useMemo(() => {
    if (!data?.interaction?.flow) {
      return null;
    }

    return keyBy(recursivelyGetProperties(data.interaction.flow), 'name');
  }, [data]);

  const contextValue = useMemo(
    () => ({
      addFlowToInteraction,
      context: data?.context || null,
      error,
      kind,
      interaction: data?.interaction || null,
      interactionKey,
      interactionReferenceId,
      interactionValues: data?.interaction?.interactionValues || null,
      isLoading,
      mutateChartPropertyValues,
      patientId,
      properties,
      refreshFlowOnInteraction,
      refreshPatientSnapshot,
      removeFlowFromInteraction,
    }),
    [
      addFlowToInteraction,
      data?.context,
      data?.interaction,
      error,
      kind,
      interactionKey,
      interactionReferenceId,
      isLoading,
      mutateChartPropertyValues,
      patientId,
      properties,
      refreshFlowOnInteraction,
      refreshPatientSnapshot,
      removeFlowFromInteraction,
    ],
  );

  return (
    <ChartInteractionContext.Provider value={contextValue}>
      {children}
    </ChartInteractionContext.Provider>
  );
};

export const ChartInteractionContextProvider: React.FC<
  Pick<
    ChartInteractionProviderProps,
    'additionalSummarizationContext' | 'interactionReferenceId' | 'kind'
  > & {
    isNightingale: boolean;
    interactionKey: ChartInteractionProviderProps['interactionKey'] | null;
    patientId?: ChartInteractionProviderProps['patientId'];
  }
> = ({
  additionalSummarizationContext,
  children,
  interactionKey,
  interactionReferenceId,
  isNightingale,
  kind,
  patientId,
}) => {
  if (!isNightingale || !patientId || !interactionKey) {
    return children as JSX.Element;
  }

  return (
    <ChartInteraction
      additionalSummarizationContext={additionalSummarizationContext}
      interactionKey={interactionKey}
      interactionReferenceId={interactionReferenceId}
      kind={kind}
      patientId={patientId}
    >
      {children}
    </ChartInteraction>
  );
};

const recursivelyGetProperties = (step: FlowStep): AnyChartProperty[] => {
  if ('elements' in step) {
    return flatMap(step.elements, recursivelyGetProperties);
  }

  return step.properties;
};
