import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useStyles = makeStyles<
  Theme,
  {
    isNightingale?: boolean;
  }
>(theme => ({
  contents: {
    flex: 1,
    // Add a substantial amount of padding at the bottom of the page to ensure
    // that the 'Add Section' dropdown always has enough room to render.
    paddingBottom: theme.spacing(50),
    backgroundColor: props => (props.isNightingale ? Colors.ChartGray : 'transparent'),
  },
  addButton: {
    fontSize: 12,
    textTransform: 'uppercase',
  },
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  grow: {
    flexGrow: 1,
  },
  labels: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  values: {
    width: '99%',
  },
  visitNotes: {
    maxWidth: 600,
  },
  schedulingSuggestions: {
    maxWidth: 600,
    '@media print': {
      display: 'none',
    },
  },
  chips: {
    paddingTop: 8,
  },
  header: {
    maxWidth: 600,
    marginBottom: theme.spacing(3),
  },
  snackbarTop: {
    marginTop: 60,
  },
}));
