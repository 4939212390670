import { gql } from 'src/generated/gql';

export const GET_ACTIVE_VISITS = gql(`
  query GetActiveDropInClinicVisits {
    getActiveDropInClinicVisits(includeZombieVisits: false) {
      patient {
        homeState
      }
      requestType
    }
  }
`);
