import { ApolloError } from 'apollo-client';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { GET_ACTIVE_VISITS } from 'src/dropInClinic/hooks/useActiveVisits.gql';
import { DropInClinicPatient } from 'src/dropInClinic/types';

export type ActiveVisit = {
  requestType: string;
  patient: Pick<DropInClinicPatient, 'homeState'>;
};

type RequestQueueResponse =
  | {
      activeVisits: ActiveVisit[];
      error: undefined;
    }
  | {
      activeVisits: null;
      error: ApolloError | undefined;
    };

export function useActiveVisits(refreshInterval?: number): RequestQueueResponse {
  const { data, error } = useTypedSWR(GET_ACTIVE_VISITS, { refreshInterval });
  if (!data?.getActiveDropInClinicVisits || error) {
    return { activeVisits: null, error };
  }

  return {
    activeVisits: data.getActiveDropInClinicVisits.map(entry => ({
      requestType: entry.requestType,
      patient: {
        homeState: entry.patient.homeState,
      },
    })),
    error: undefined,
  };
}
