import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  flowHeader: {
    alignItems: 'center',
    color: Colors.Gray5,
    display: 'flex',
    flexDirection: 'row',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 1.12,
    lineHeight: '145%',
    marginBottom: 24,
    marginTop: 0,
    textTransform: 'uppercase',
    width: '100%',
    '&::after': {
      borderBottom: `1px solid ${Colors.Gray5}`,
      content: '""',
      display: 'flex',
      flexGrow: 1,
      marginLeft: 8,
    },
  },
});

/**
 * Heading component for `Flow`s and `ChartOverviewSection`s.
 *
 * @deprecated Use `SectionHeader` from `src/common/ui/components` instead.
 */
export const SectionHeader: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <h3 className={styles.flowHeader} data-testid="section-header">
      {children}
    </h3>
  );
};
